const permissionsPerModules = {
  app_administration_permission: {
    name: "app_administration",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  tenant_permission: {
    name: "Organization",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  tenant_user_permission: {
    name: "Users",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      }
    ]
  },
  dashboard_permission: {
    name: "Dashboard",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 32,
        name: "Print"
      }
    ]
  },
  opportunity_permission: {
    name: "Opportunity",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  task_permission: {
    name: "Task",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 128,
        name: "Create Tasks for other Org Reps"
      },
      {
        permissionValue: 256,
        name: "View other Org Reps Tasks"
      }
    ]
  },
  // proposal_permission: {
  //     name: 'Proposal',
  //     list: [
  //         {
  //             permissionValue: 1,
  //             name: 'View'
  //         },
  //         {
  //             permissionValue: 2,
  //             name: 'Add'
  //         },
  //         {
  //             permissionValue: 4,
  //             name: 'Edit'
  //         },
  //         {
  //             permissionValue: 8,
  //             name: 'Delete'
  //         },
  //         {
  //             permissionValue: 32,
  //             name: 'Print'
  //         },
  //         {
  //             permissionValue: 64,
  //             name: 'Import/Export'
  //         },
  //         {
  //             permissionValue: 128,
  //             name: 'Needs Approval'
  //         },
  //         {
  //             permissionValue: 256,
  //             name: 'Can\'t send over 10K'
  //         },
  //         // {
  //         //     permissionValue: 512,
  //         //     name: 'PERM 1'
  //         // },
  //         // {
  //         //     permissionValue: 1024,
  //         //     name: 'PERM 2'
  //         // },
  //         // {
  //         //     permissionValue: 2048,
  //         //     name: 'PERM 3'
  //         // },
  //         // {
  //         //     permissionValue: 4096,
  //         //     name: 'PERM 4'
  //         // },
  //         // {
  //         //     permissionValue: 8192,
  //         //     name: 'PERM 5'
  //         // },
  //         // {
  //         //     permissionValue: 16384,
  //         //     name: 'PERM 6'
  //         // },
  //         // {
  //         //     permissionValue: 32768,
  //         //     name: 'PERM 7'
  //         // },
  //         // {
  //         //     permissionValue: 65536,
  //         //     name: 'PERM 8'
  //         // },
  //         // {
  //         //     permissionValue: 131072,
  //         //     name: 'PERM 9'
  //         // },
  //         // {
  //         //     permissionValue: 262144,
  //         //     name: 'PERM 10'
  //         // },
  //         {
  //             permissionValue: 1073741824,
  //             name: 'TEST 1'
  //         }
  //     ]
  // },
  document_permission: {
    name: "Document",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 32,
        name: "Print"
      },
      {
        permissionValue: 128,
        name: "Can Approve"
      }
    ]
  },
  company_permission: {
    name: "Company",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 32,
        name: "Print"
      },
      {
        permissionValue: 64,
        name: "Import"
      },
      {
        permissionValue: 128,
        name: "Export"
      }
    ]
  },
  contact_permission: {
    name: "Contact",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 32,
        name: "Print"
      },
      {
        permissionValue: 64,
        name: "Import"
      },
      {
        permissionValue: 128,
        name: "Export"
      }
    ]
  },
  template_permission: {
    name: "Email Template",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  workflow_permission: {
    name: "Workflow",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  sequence_permission: {
    name: "Sequence",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      }
    ]
  },
  product_service_permission: {
    name: "Product Service",
    list: [
      {
        permissionValue: 1,
        name: "View"
      },
      {
        permissionValue: 2,
        name: "Add"
      },
      {
        permissionValue: 4,
        name: "Edit"
      },
      {
        permissionValue: 8,
        name: "Delete"
      },
      {
        permissionValue: 64,
        name: "Import"
      },
      {
        permissionValue: 128,
        name: "Export"
      }
    ]
  }
};

export default permissionsPerModules;
