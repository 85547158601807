const emailTypes = [
  {
    code: 1,
    name: "Microsoft",
    url: "auth/microsoft"
  },
  {
    code: 2,
    name: "Gmail",
    url: "auth/google"
  }
];

export default emailTypes;
