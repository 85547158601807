import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, isEqual } from "lodash";
import { useDidUpdate } from "hooks";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import HeaderActionButton from "components/common/header-action-button";
import ActionDialog from 'components/complex/dialogs/action';
import TableViewDialog from 'components/complex/table-view/table-view-dialog';

import { Box, Divider, FormControlLabel, MenuItem, Switch, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { callDeleteCustomTableView, callEditCustomTableViewpromise, callSelectCustomTableView, CUSTOM_TABLE_VIEW_DELETE_CALL } from "store/table-view/sagas-actions";
import { errorSelectorById } from "store/error/selector";
import { customTableViewDataSelector } from "store/table-view/selectors";
import { onKeepTableView } from "store/table-view/slice";
import Import from "components/complex/import";
import Export from "components/complex/export";


/**
 * This hook provides table view header
 * @param {String} moduleValue - Module value
 * @param {String} defaultViewText - Text for default first item in list
 * @param {Object} permissions - Module permissions
 * @param {Object} tableRef - Table ref
 */

const useStyles = makeStyles(() => ({
  divider: {
    margin: "0 10px"
  },
  toggleButtons: {
    margin: "0 5px"
  }
}));

function useTableViewHeader(
  moduleValue,
  defaultViewText,
  myItemsText,
  permissions,
  tableRef,
  selected,
  tenantUserId,
  viewId,
  action,
  defaultColumns
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    list,
    isLoading: isLoadingView,
    selectedTableView,
    isFilteredByTenantUser
  } = useSelector((store) => customTableViewDataSelector(store, moduleValue), isEqual);
  const resErrorDelete = useSelector((store) => errorSelectorById(store, CUSTOM_TABLE_VIEW_DELETE_CALL), isEqual);

  const [selectedView, setSelectedView] = useState(selectedTableView || list[0] || {});
  const [addView, setAddView] = useState();
  const [viewToEdit, setViewToEdit] = useState();
  const [viewToDeleteId, setViewToDeleteId] = useState();
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(undefined);

  function selectView(viewId) {
    const listSettings = {};
    listSettings.list_settings = {
      [`${moduleValue}_list_view_id`]: viewId === "default_view" || viewId === "my_view" ? null : viewId
    };
    const view = list.find((v) => v.table_view_id === viewId);
    setSelectedView(view || {});
    dispatch(callSelectCustomTableView(tenantUserId, listSettings));

    if (viewId === "my_view") {
      dispatch(onKeepTableView({ key: moduleValue, view: {}, isFilteredByTenantUser: true }));
      dispatch(action);
    } else {
      dispatch(onKeepTableView({ key: moduleValue, view: view || {}, isFilteredByTenantUser: false }));
    }
  }

  useEffect(() => {
    const view = list.find((v) => v.table_view_id === viewId);
    setSelectedView(view || {});
    dispatch(onKeepTableView({ key: moduleValue, view: view || {}, isFilteredByTenantUser }));
  }, []);

  const viewAction = (action) => () => {
    switch (action) {
      case "create": {
        setAddView(true);
        break;
      }
      case "edit": {
        setViewToEdit(selectedView);
        break;
      }
      case "save": {
        if (selectedView?.table_view_id !== undefined) {
          dispatch(
            callEditCustomTableViewpromise({
              moduleValue,
              viewId: selectedView.table_view_id,
              viewData: selectedView,
              withoutLoading: true
            })
          ).then((data) => {
            if (data) {
              enqueueSnackbar("View saved.", {
                variant: "success"
              });
            }
          });
        }
        break;
      }
      case "delete": {
        setViewToDeleteId(selectedView.table_view_id);
        break;
      }
      case "reset": {
        tableRef.current.resetView();
        break;
      }
      default:
        break;
    }
  };

  const visibleColumnNames = useMemo(() => {
    if (selectedView.data && selectedView.data.columns) {
      return selectedView.data.columns.reduce((acc, c) => {
        if (c.visible) {
          acc.push(c.name);
        }
        return acc;
      }, []);
    }
    if (defaultColumns?.length) {
      return defaultColumns.map(c => c.column_name);
    }
  }, [selectedView, defaultColumns]);

  // Switch to new added view after creating
  useDidUpdate(() => {
    // setSelectedView(list[list.length - 1] || {});
    setSelectedView(selectedTableView || {});
  }, [list.length]);

  const importLabels = useMemo(
    () => ({
      contact: "Contacts",
      company: "Companies",
      product_service: "Products/Services",
      opportunity: "Opportunities"
    }),
    []
  );

  const TableViewHeader = useMemo(
    () => (
      <>
        <Box display="flex" alignItems="center">
          <TextField
            select
            size="small"
            variant="outlined"
            onChange={(e) => selectView(e.target.value)}
            value={
              isFilteredByTenantUser
                ? "my_view"
                : isEmpty(selectedView)
                  ? "default_view"
                  : selectedView?.table_view_id || ""
            }
            disabled={list.length === 0}
            style={{
              width: "30%",
              minWidth: "100px"
            }}>
            <MenuItem value="default_view">{defaultViewText}</MenuItem>
            <MenuItem value="my_view" disabled={!action}>
              {myItemsText}
            </MenuItem>
            <Divider />
            {list &&
              list.map((option) => (
                <MenuItem key={option.table_view_id} value={option.table_view_id}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
          {permissions.Add && (
            <HeaderActionButton
              title="Create view"
              onClick={viewAction("create")}
              disabled={isEmpty(selectedView)}
              size="small"
            />
          )}
          {permissions.Edit && (
            <>
              <HeaderActionButton
                title="Edit view"
                onClick={viewAction("edit")}
                disabled={selectedView?.table_view_id === undefined}
                size="small"
              />
              <HeaderActionButton
                title="Save view"
                onClick={viewAction("save")}
                disabled={selectedView?.table_view_id === undefined}
                size="small"
              />
            </>
          )}
          {permissions.Delete && (
            <HeaderActionButton
              title="Delete view"
              onClick={viewAction("delete")}
              disabled={selectedView?.table_view_id === undefined}
              size="small"
            />
          )}
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <HeaderActionButton
            title="Reset view"
            onClick={viewAction("reset")}
            disabled={selectedView?.table_view_id === undefined}
            size="small"
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  selectedView?.data?.filteringStatus !== undefined && Boolean(selectedView?.data?.filteringStatus)
                }
                onChange={() => tableRef.current.toggleFilters()}
                color="primary"
                size="small"
              />
            }
            label="Filters"
            className={classes.toggleButtons}
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  selectedView?.data?.disableGroupByToolbar !== undefined && !selectedView.data.disableGroupByToolbar
                }
                onChange={() => tableRef.current.toggleGrouping()}
                color="primary"
                size="small"
              />
            }
            label="Groups"
            className={classes.toggleButtons}
          />
          <Divider className={classes.divider} orientation="vertical" flexItem />
          {(moduleValue === "company" || moduleValue === "contact" || moduleValue === "product_service") && (
            <>
              {permissions.Import && (
                <>
                  <HeaderActionButton
                    title={`Import ${importLabels[moduleValue]}`}
                    onClick={() => setOpenImportDialog(true)}
                    size="small"
                  />
                  <Import
                    moduleName={moduleValue}
                    handleClose={() => setOpenImportDialog(false)}
                    isOpen={openImportDialog}
                  />
                </>
              )}
              {permissions.Export && (
                <>
                  <HeaderActionButton
                    title={`Export ${moduleValue}`}
                    onClick={() => setOpenExportDialog(true)}
                    size="small"
                    disabled={!(selected.length > 0)}
                  />
                  <Export
                    isOpen={openExportDialog}
                    closePopup={() => setOpenExportDialog(false)}
                    selectedIds={selected}
                    moduleValue={moduleValue}
                    columns={visibleColumnNames}
                  />
                </>
              )}
            </>
          )}
        </Box>

        {permissions.Add && (
          <TableViewDialog
            moduleValue={moduleValue}
            isNew
            isOpen={addView}
            viewData={selectedView}
            closePopup={() => setAddView(false)}
          />
        )}
        {permissions.Edit && (
          <TableViewDialog
            moduleValue={moduleValue}
            viewData={viewToEdit}
            isOpen={viewToEdit !== undefined}
            closePopup={() => setViewToEdit(undefined)}
          />
        )}

        {permissions.Delete && viewToDeleteId && (
          <ActionDialog
            title="Delete selected View?"
            description="Do you want to permanently delete selected View?"
            successSnackMessage="View deleted."
            actionFunction={() => dispatch(callDeleteCustomTableView(moduleValue, viewToDeleteId))}
            isOpen={viewToDeleteId !== undefined}
            isLoading={isLoadingView}
            closePopup={() => setViewToDeleteId(undefined)}
            errorId={CUSTOM_TABLE_VIEW_DELETE_CALL}
          />
        )}
      </>
    ),
    [
      classes,
      moduleValue,
      permissions,
      tableRef,
      list,
      selectedView,
      isFilteredByTenantUser,
      isLoadingView,
      addView,
      viewToEdit,
      openImportDialog,
      openExportDialog,
      viewToDeleteId,
      selected
    ]
  );

  return [selectedView, setSelectedView, TableViewHeader, isFilteredByTenantUser];
}

useTableViewHeader.propTypes = {
  moduleValue: PropTypes.string.isRequired,
  defaultViewText: PropTypes.string,
  permissions: PropTypes.shape({
    Add: PropTypes.bool,
    Edit: PropTypes.bool,
    Delete: PropTypes.bool
  }).isRequired,
  tableRef: PropTypes.shape({
    current: PropTypes.shape({}).isRequired
  }).isRequired
};

useTableViewHeader.defaultProps = {
  defaultViewText: "All"
};

export default useTableViewHeader;
