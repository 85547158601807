import { useMemo } from "react";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { calculatePermissionStatus } from "utils/helpers";
import { userModulePermissionSelector } from "store/user/selectors";
import { permissionsSelector } from "../../../../store/settings/permissions/selectors";

/**
 * This hook returns permissions for current user
 * @param {String} moduleName - Module name
 * @param {String} permissionName - Specific name of permission (optional)
 */

const usePermission = (moduleName, permissionName) => {
  const { data: permissionsPerModules } = useSelector(permissionsSelector, isEqual);

  const moduleValueOfPermissionsForUser = useSelector(
    (store) => userModulePermissionSelector(store, moduleName),
    isEqual
  );
  const permissionsForModule = permissionsPerModules[moduleName]?.list;

  return useMemo(() => {
    if (permissionName !== undefined) {
      const singlePermission = permissionsForModule.find((p) => p.name === permissionName);
      return calculatePermissionStatus(moduleValueOfPermissionsForUser, singlePermission?.permissionValue || 0);
    }

    const permissions = {};
    permissionsForModule.forEach((p) => {
      permissions[p.name] = calculatePermissionStatus(moduleValueOfPermissionsForUser, p?.permissionValue || 0);
    });
    return permissions;
  }, [moduleValueOfPermissionsForUser]);
};

export default usePermission;
