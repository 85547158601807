import { createPromiseAction } from "@adobe/redux-saga-promise";

export const CONTACTS_CALL = "CONTACTS_CALL";
export const CONTACTS_CALL_PROMISE = "CONTACTS_CALL_PROMISE";
export const CONTACT_EDIT_CALL_PROMISE = "CONTACT_EDIT_CALL_PROMISE";
export const CONTACT_CALL = "CONTACT_CALL";
export const CONTACT_ADD_CALL = "CONTACT_ADD_CALL";
export const CONTACT_ADD_CALL_PROMISE = "CONTACT_ADD_CALL_PROMISE";
export const CONTACT_EDIT_CALL = "CONTACT_EDIT_CALL";
export const CONTACT_DELETE_CALL = "CONTACT_DELETE_CALL";
export const CONTACT_RESTORE_CALL = "CONTACT_RESTORE_CALL";

export const CONTACT_NOTES_CALL = "CONTACT_NOTES_CALL";

export const CONTACT_TASKS_CALL = "CONTACT_TASKS_CALL";

export const CONTACTS_CALL_STATUSES = "CONTACTS_CALL_STATUSES";
export const CONTACTS_CALL_TYPES = "CONTACTS_CALL_TYPES";
export const CONTACTS_CALL_GROUPS = "CONTACTS_CALL_GROUPS";
export const CONTACTS_CALL_ORGREPS = "CONTACTS_CALL_ORGREPS";
export const CONTACTS_CALL_CUSTOM_FIELDS = "CONTACTS_CALL_CUSTOM_FIELDS";
export const CONTACTS_CALL_SOCIAL_MEDIAS = "CONTACTS_CALL_SOCIAL_MEDIAS";
export const CONTACTS_CALL_SEQUENCES = "CONTACTS_CALL_SEQUENCES";
export const CONTACT_CALL_SEQUENCES = "CONTACT_CALL_SEQUENCES";
export const CONTACT_CALL_APOLLO_DATA = "CONTACT_CALL_APOLLO_DATA";

export const CONTACTS_CHANGE_STATUSES = "CONTACTS_CHANGE_STATUSES";
export const CONTACTS_CHANGE_TYPES = "CONTACTS_CHANGE_TYPES";
export const CONTACTS_CHANGE_GROUPS = "CONTACTS_CHANGE_GROUPS";
export const CONTACTS_CHANGE_ORG_REP = "CONTACTS_CHANGE_ORG_REP";
export const CONTACTS_CHANGE_SEQUENCES = "CONTACTS_CHANGE_SEQUENCES";
export const CONTACTS_CHANGE_SUBSCRIPTION = "CONTACTS_CHANGE_SUBSCRIPTION";

export const CONTACTS_CHECK_EMAIL_VALIDATION = "CONTACTS_CHECK_EMAIL_VALIDATION";

export const callContactsPromise = createPromiseAction(CONTACTS_CALL_PROMISE);
export const callContactAddPromise = createPromiseAction(CONTACT_ADD_CALL_PROMISE);
export const callSingleContactAddPromise = createPromiseAction(CONTACT_ADD_CALL);
export const callContactEditPromise = createPromiseAction(CONTACT_EDIT_CALL_PROMISE);

export function callContacts(paginationData, tenantUserId, withoutLoading) {
  return { type: CONTACTS_CALL, payload: { paginationData, tenantUserId, withoutLoading } };
}

export function callContact(contactId, withoutLoading) {
  return { type: CONTACT_CALL, payload: { contactId, withoutLoading } };
}

export function callContactApolloData(contactEmail) {
  return { type: CONTACT_CALL_APOLLO_DATA, payload: { contactEmail } };
}

export function callContactNotes(entityId, module, isSocketUpdate) {
  return { type: CONTACT_NOTES_CALL, payload: { entityId, module, isSocketUpdate } };
}

export function callContactTasks(contactId) {
  return { type: CONTACT_TASKS_CALL, payload: { contactId } };
}

export function callAddContact(contactData, newPhotoFile) {
  return { type: CONTACT_ADD_CALL, payload: { contactData, newPhotoFile } };
}

export function callEditContact(contactId, contactData, newPhotoFile) {
  return { type: CONTACT_EDIT_CALL, payload: { contactId, contactData, newPhotoFile } };
}

export function callDeleteContacts(contactIds) {
  return { type: CONTACT_DELETE_CALL, payload: { contactIds } };
}

export function callContactAttributes(type) {
  return { type };
}

export function callContactSequences() {
  return { type: CONTACTS_CALL_SEQUENCES };
}

export function callContactSequencesList(contactId) {
  return { type: CONTACT_CALL_SEQUENCES, payload: { contactId } };
}

export function callContactsChangeAttributes(type, data) {
  return { type, payload: { data } };
}

export function callChangeContactsSequences(data, messageFunction) {
  return { type: CONTACTS_CHANGE_SEQUENCES, payload: { data, messageFunction } };
}

export function callChangeContactsSubscription({ contactIds, type, unsubscribe }) {
  return { type: CONTACTS_CHANGE_SUBSCRIPTION, payload: { contactIds, type, unsubscribe } };
}

export function callCheckContactEmailValidation(email) {
  return { type: CONTACTS_CHECK_EMAIL_VALIDATION, payload: { email } };
}
