import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import axiosInstance from "clients/api";
import usePermission from "components/complex/permission/hook";
import useStateComplex from "hooks/common/useStateComplex";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form-v7";
import useStyles from "../style";

const SelectWithAddSearch = memo(
  ({
     createDialog: CreateDialog,
     control,
     setValue,
     setError,
     clearErrors,
     error,
     url,
     name,
     apiDataKey,
     label,
     selectCompany,
     moduleName,
     initialData,
     disabled,
     combineName,
     valueDefault,
     getNameCallback,
     customItem,
   }) => {
    const isWithAddNewFeature = !!CreateDialog;
    const classes = useStyles();
    const addPermission = usePermission(moduleName, "Add");
    const [defaultValue, setDefaultValue] = useState(valueDefault);
    const [addNew, setAddNew] = useState(false);
    const [state, setState] = useStateComplex({
      isLoading: false,
      list: customItem ? [...customItem] : []
    });



    const axiosSourceRef = useRef(null);

    const setTableData = (data) => {
      clearErrors(name);
      const newData = data?.tableData?.map((c) => ({
        code: c[apiDataKey || name],
        name: combineName ? `${c.name} | ${c.email}` : c.name,
        ...(selectCompany &&
          c?.company?.company_id && {
            company: {
              code: c.company.company_id,
              name: c.company.name
            }
          })
      }));
      setState({
        list: customItem ? [...customItem, ...newData] : [...newData],
        isLoading: false
      });

      if (defaultValue) {
        setValue(name, defaultValue, {
          shouldDirty: true,
          shouldValidate: true
        });
      }

    };

    const loadData = async (searchValue) => {
      setState({
        list: state.list,
        isLoading: true
      });
      clearErrors(name);
      if (axiosSourceRef.current) {
        axiosSourceRef.current.cancel();
      }

      axiosSourceRef.current = axios.CancelToken.source();

      try {
        const { data } = await axiosInstance.get(url, {
          cancelToken: axiosSourceRef.current.token,
          params: {
            search_value: searchValue
          }
        });
        setTableData(data);
      } catch (err) {
        setError(name, {
          type: `${name}.failed`,
          message: `Failed to load ${label}(s)!`
        });

        setState({
          isLoading: false
        });
      }
    };

    // On item click
    const onSelect = useCallback(
      (props, data) => {
        if (isWithAddNewFeature && data?.index === 0) {
          // Add new item
          setAddNew(true);
        } else {
          // Select from items list
          const d = { ...data };

          if (selectCompany && d?.company) {
            selectCompany(d.company);
            delete d.company;
          }

          props.onChange(d);
        }
      },
      [isWithAddNewFeature, selectCompany]
    );

    // On new item create
    const onItemCreate = useCallback(
      (itemData) => {
        const createdItem = {
          code: itemData.code,
          name: itemData.name
        };
        setState({
          list: [itemData, ...state.list]
        });

        setDefaultValue(itemData);
        // Set current key in form
        setValue(name, createdItem, {
          shouldDirty: true,
          shouldValidate: true
        });

        // Set company if needed
        if (selectCompany && itemData?.company) {
          selectCompany(itemData.company);
        }
      },
      [selectCompany, state?.list]
    );

    useEffect(() => {
      if (initialData) {
        if (initialData?.tableData) {
          setTableData(initialData);
        }
      } else {
        setTimeout(loadData, 0);
      }
    }, [initialData]);

    return (
      <>
        <Controller
          key={defaultValue}
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Autocomplete
              {...field}
              size="small"
              className={classes.autoCompleteRoot}
              options={
                isWithAddNewFeature && addPermission
                  ? [
                    {
                      index: 0,
                      code: "add-new",
                      name: `+ Add New ${label}`
                    },
                    ...state.list
                  ]
                  : state.list
              }
              loading={state.isLoading}
              loadingText="Loading..."
              // disableClearable
              disabled={disabled}
              // isOptionEqualToValue={(option, value) => option.code === value}
              getOptionLabel={(option) => option.name || ""}
              renderOption={({ key, ...optionProps }, option, { selected }) => (
                <li {...optionProps} key={option.code}>
                  {getNameCallback ? getNameCallback(option) : option.name}
                </li>
              )}
              // renderOption={option => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  className={classes.field}
                  error={Boolean(error)}
                  // helperText={<span>{error?.message || error?.code?.message || ''}</span>}
                  helperText={<span>{error || null}</span>}
                  FormHelperTextProps={{
                    className: classes.errorText
                  }}
                  inputProps={{
                    ...params.inputProps,
                    className: classes.input,
                    autoComplete: "new-password"
                  }}
                  // Loading spinner
                  // InputProps={{
                  //     ...params.InputProps,
                  //     endAdornment: (
                  //         <>
                  //             {!state.isLoading ? <CircularProgress size={24} thickness={5} style={{ padding: '2px' }}/> : null}
                  //             {params.InputProps.endAdornment}
                  //         </>
                  //     )
                  // }}
                  form={{
                    autoComplete: "off"
                  }}
                  InputLabelProps={{
                    classes: {
                      root: `${classes.label} ${classes.labelNotCapitalize}`,
                      focused: classes.labelFocused,
                      shrink: classes.labelShrink
                    }
                  }}
                  onChange={(e) => loadData(e.target.value)}
                  disabled={disabled}
                />
              )}
              onChange={(_, data) => onSelect(field, data)}
              // value="" // TODO -> fix for uncontrolled select
            />
          )}
        />

        {isWithAddNewFeature && addPermission && addNew && (
          <CreateDialog isOpen={addNew} closePopup={() => setAddNew(false)} onItemCreate={onItemCreate} />
        )}
      </>
    );
  }
);

SelectWithAddSearch.propTypes = {
  createDialog: PropTypes.func,
  control: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selectCompany: PropTypes.func
};

SelectWithAddSearch.defaultProps = {
  selectCompany: undefined,
  createDialog: undefined,
  error: undefined,
  disabled: false
};

export default SelectWithAddSearch;
