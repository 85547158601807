export const USERS_CALL = "USERS_CALL";
export const USER_CALL = "USER_CALL";
export const USER_ADD_CALL = "USER_ADD_CALL";
export const USER_EDIT_CALL = "USER_EDIT_CALL";
export const USER_EDIT_PATCH_CALL = "USER_EDIT_PATCH_CALL";
export const USER_SECURITY_ROLES_CALL = "USER_SECURITY_ROLES_CALL";
export const USER_UI_SETTINGS_EDIT_CALL = "USER_UI_SETTINGS_EDIT_CALL";
export const USER_CHARGE_CALL = "USER_CHARGE_CALL";

export function callUsers() {
  return { type: USERS_CALL };
}

export function callUser(userId) {
  return { type: USER_CALL, payload: { userId } };
}

export function callAddUser(userData, newPhotoFile, messageFunction) {
  return { type: USER_ADD_CALL, payload: { userData, newPhotoFile, messageFunction } };
}

export function callCharge() {
  return { type: USER_CHARGE_CALL };
}

export function callEditUser(userId, userData, newPhotoFile, timezone, messageFunction) {
  return { type: USER_EDIT_CALL, payload: { userId, userData, newPhotoFile, timezone, messageFunction } };
}

export function callEditUserPatch(userId, userData, isAuth, messageFunction) {
  return { type: USER_EDIT_PATCH_CALL, payload: { userId, userData, isAuth, messageFunction } };
}

export function callEditUiSettingsUser(userId, userUiData) {
  return { type: USER_UI_SETTINGS_EDIT_CALL, payload: { userId, userUiData } };
}

export function callUserSecurityRoles() {
  return { type: USER_SECURITY_ROLES_CALL };
}
