import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    currencyExchangeRates: {
      // isFetched: false,
      // data: undefined
      // TODO -> replace this
      isFetched: true,
      data: {
        AED: 4.362226,
        AFN: 94.776152,
        ALL: 122.092035,
        AMD: 577.442812,
        ANG: 2.124633,
        AOA: 758.90781,
        ARS: 114.735169,
        AUD: 1.607278,
        AWG: 2.13777,
        AZN: 2.016474,
        BAM: 1.961163,
        BBD: 2.389899,
        BDT: 100.378484,
        BGN: 1.958116,
        BHD: 0.447775,
        BIF: 2345.729814,
        BMD: 1.18765,
        BND: 1.60945,
        BOB: 8.173027,
        BRL: 6.07685,
        BSD: 1.183614,
        BTC: 0.000029545471,
        BTN: 87.990565,
        BWP: 13.151556,
        BYN: 2.981508,
        BYR: 23277.937385,
        BZD: 2.385889,
        CAD: 1,
        CDF: 2376.487155,
        CHF: 1.079456,
        CLF: 0.032793,
        CLP: 904.870393,
        CNY: 7.671507,
        COP: 4600.361758,
        CRC: 733.582657,
        CUC: 1.18765,
        CUP: 31.472721,
        CVE: 110.565688,
        CZK: 25.515432,
        DJF: 210.713043,
        DKK: 7.438134,
        DOP: 67.478374,
        DZD: 159.934868,
        EGP: 18.690757,
        ERN: 17.819795,
        ETB: 52.506911,
        EUR: 0.75,
        FJD: 2.47429,
        FKP: 0.858387,
        GBP: 0.850755,
        GEL: 3.663913,
        GGP: 0.858387,
        GHS: 7.078209,
        GIP: 0.858387,
        GMD: 60.748699,
        GNF: 11562.750698,
        GTQ: 9.173286,
        GYD: 247.635541,
        HKD: 9.230949,
        HNL: 28.106965,
        HRK: 7.510812,
        HTG: 113.514797,
        HUF: 358.272388,
        IDR: 17188.381459,
        ILS: 3.852902,
        IMP: 0.858387,
        INR: 88.219285,
        IQD: 1726.926453,
        IRR: 50005.997578,
        ISK: 147.398963,
        JEP: 0.858387,
        JMD: 183.93412,
        JOD: 0.842036,
        JPY: 130.465758,
        KES: 128.980447,
        KGS: 100.696073,
        KHR: 4819.726296,
        KMF: 494.062363,
        KPW: 1068.884887,
        KRW: 1360.725753,
        KWD: 0.356901,
        KYD: 0.986383,
        KZT: 504.070843,
        LAK: 11300.231481,
        LBP: 1789.667079,
        LKR: 235.544173,
        LRD: 203.860346,
        LSL: 17.624508,
        LTL: 3.506821,
        LVL: 0.718397,
        LYD: 5.351353,
        MAD: 10.597621,
        MDL: 21.204868,
        MGA: 4500.371066,
        MKD: 61.793049,
        MMK: 1948.269806,
        MNT: 3382.562888,
        MOP: 9.487101,
        MRO: 423.990798,
        MUR: 50.819994,
        MVR: 18.349425,
        MWK: 961.710316,
        MXN: 23.586489,
        MYR: 5.033854,
        MZN: 75.570038,
        NAD: 17.624442,
        NGN: 487.067208,
        NIO: 41.562331,
        NOK: 10.39696,
        NPR: 140.780302,
        NZD: 1.699723,
        OMR: 0.457243,
        PAB: 1.183619,
        PEN: 4.648141,
        PGK: 4.155967,
        PHP: 59.802933,
        PKR: 192.013464,
        PLN: 4.585528,
        PYG: 8197.292902,
        QAR: 4.324255,
        RON: 4.922217,
        RSD: 117.895622,
        RUB: 86.880029,
        RWF: 1192.172127,
        SAR: 4.454299,
        SBD: 9.574087,
        SCR: 17.017153,
        SDG: 530.281045,
        SEK: 10.183195,
        SGD: 1.608196,
        SHP: 0.858387,
        SLL: 12179.349201,
        SOS: 694.775041,
        SRD: 25.398488,
        STD: 24465.378263,
        SVC: 10.357167,
        SYP: 1493.310258,
        SZL: 17.509017,
        THB: 39.049981,
        TJS: 13.499469,
        TMT: 4.168651,
        TND: 3.314139,
        TOP: 2.686761,
        TRY: 10.097322,
        TTD: 8.034149,
        TWD: 33.142679,
        TZS: 2754.160004,
        UAH: 31.795702,
        UGX: 4201.962805,
        USD: 1,
        UYU: 51.849443,
        UZS: 12603.425576,
        VEF: 253955435027.9191,
        VND: 27277.34831,
        VUV: 130.782461,
        WST: 3.030474,
        XAF: 657.740216,
        XAG: 0.046599,
        XAU: 0.000652,
        XCD: 3.209683,
        XDR: 0.832958,
        XOF: 657.745769,
        XPF: 120.486867,
        YER: 297.01275,
        ZAR: 17.371814,
        ZMK: 10690.331835,
        ZMW: 22.696324,
        ZWL: 382.423541
      }
    },
    dynamicContents: {
      isFetched: false,
      data: {}
    }
  },
  reducers: {
    onCallGeneric: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].data = [];
    },
    onSuccessGeneric: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].data = get(payload?.data, payload?.apiReturnKey || "data");
    },
    onFailedGeneric: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].data = [];
    },
    onSuccessDynamicContents: (state, { payload }) => {
      state.dynamicContents.isFetched = true;
      state.dynamicContents.data = {
        ...state.dynamicContents.data,
        [payload.id]: payload.data && payload.data.content ? payload.data.content[0] : {}
      };
    }
    // switchLoadingGeneric: (state, { payload }) => {
    //     state[payload.key].isLoading = payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    // }
  }
});

export const { onCallGeneric, onSuccessGeneric, onFailedGeneric, onSuccessDynamicContents } = generalSlice.actions;

export default generalSlice.reducer;
