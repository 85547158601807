/* eslint-disable array-callback-return */
import { createSlice, current } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import _, { cloneDeep } from "lodash";
import { calculateAdjustments, calculateAll } from "utils/calculations";

function parseDocumentProductService(prodData) {
  return {
    ...prodData,
    quantity: parseFloat(prodData.quantity),
    sell: parseFloat(prodData.sell),
    cost: parseFloat(prodData.cost),
    total: parseFloat(prodData.total),
    discount: parseFloat(prodData.discount)
  };
}

function addManufacturer(productServices) {
  const manufacturerIds = [];
  productServices.map((pc) => {
    if (pc.manufacturer_id && !manufacturerIds.includes(pc.manufacturer_id, 0)) {
      manufacturerIds.push(pc.manufacturer_id);
    }
  });
  return manufacturerIds;
}

// function calculateAdjustments(
//   productServices,
//   productServiceItemIds,
//   adjustments
// ) {
//   console.log({ productServices }, 'calculateAdjustments');
//   if (adjustments && adjustments.length) {
//     let newProductServices = [...productServices];
//     adjustments.map((ad) => {
//       if (ad.Source === 'Miscellaneous') {
//         newProductServices = newProductServices.map((pc) => {
//           if (pc.include_in_adjustment) {
//             const key = ad.Destination === 'Sell Price' ? 'sell' : 'cost';
//             if (ad.adjustment_value_direction_id === 0) {
//               if (pc[`document_${key}`] <= 0) {
//                 pc[`document_${key}`] = 0;
//                 return pc;
//               }
//               pc[`document_${key}`] = pc[`document_${key}`]
//                 ? ad.Type === 'Amount'
//                   ? pc[`document_${key}`] - Number(ad.Value)
//                   : pc[`document_${key}`] - (pc[key] * Number(ad.Value)) / 100
//                 : ad.Type === 'Amount'
//                 ? pc[key] - Number(ad.Value)
//                 : pc[key] - (pc[key] * Number(ad.Value)) / 100;
//               return pc;
//             }
//             if (ad.adjustment_value_direction_id === 1) {
//               if (pc[`document_${key}`] <= 0) {
//                 pc[`document_${key}`] = 0;
//                 return pc;
//               }
//               pc[`document_${key}`] = pc[`document_${key}`]
//                 ? ad.Type === 'Amount'
//                   ? pc[`document_${key}`] + Number(ad.Value)
//                   : pc[`document_${key}`] + (pc[key] * Number(ad.Value)) / 100
//                 : ad.Type === 'Amount'
//                 ? pc[key] + Number(ad.Value)
//                 : pc[key] + (pc[key] * Number(ad.Value)) / 100;
//               return pc;
//             }
//           }
//           return pc;
//         });
//       } else if (ad.Source === 'Location') {
//         newProductServices = newProductServices.map((pc) => {
//           if (pc.include_in_adjustment) {
//             if (pc.location === ad.Parameter) {
//               const key = ad.Destination === 'Sell Price' ? 'sell' : 'cost';
//               if (ad.adjustment_value_direction_id === 0) {
//                 if (pc[`document_${key}`] <= 0) {
//                   pc[`document_${key}`] = 0;
//                   return pc;
//                 }
//                 pc[`document_${key}`] = pc[`document_${key}`]
//                   ? ad.Type === 'Amount'
//                     ? pc[`document_${key}`] - Number(ad.Value)
//                     : pc[`document_${key}`] - (pc[key] * Number(ad.Value)) / 100
//                   : ad.Type === 'Amount'
//                   ? pc[key] - Number(ad.Value)
//                   : pc[key] - (pc[key] * Number(ad.Value)) / 100;
//                 return pc;
//               }
//               if (ad.adjustment_value_direction_id === 1) {
//                 if (pc[`document_${key}`] <= 0) {
//                   pc[`document_${key}`] = 0;
//                   return pc;
//                 }
//                 pc[`document_${key}`] = pc[`document_${key}`]
//                   ? ad.Type === 'Amount'
//                     ? pc[`document_${key}`] + Number(ad.Value)
//                     : pc[`document_${key}`] + (pc[key] * Number(ad.Value)) / 100
//                   : ad.Type === 'Amount'
//                   ? pc[key] + Number(ad.Value)
//                   : pc[key] + (pc[key] * Number(ad.Value)) / 100;
//                 return pc;
//               }
//             }
//             return pc;
//           }
//           return pc;
//         });
//       } else if (ad.Source === 'Manufacturer') {
//         newProductServices = newProductServices.map((pc) => {
//           if (pc.include_in_adjustment) {
//             if (pc.manufacturer_id == ad.Parameter) {
//               const key = ad.Destination === 'Sell Price' ? 'sell' : 'cost';
//               if (ad.adjustment_value_direction_id === 0) {
//                 if (pc[`document_${key}`] <= 0) {
//                   pc[`document_${key}`] = 0;
//                   return pc;
//                 }
//                 pc[`document_${key}`] = pc[`document_${key}`]
//                   ? ad.Type === 'Amount'
//                     ? pc[`document_${key}`] - Number(ad.Value)
//                     : pc[`document_${key}`] - (pc[key] * Number(ad.Value)) / 100
//                   : ad.Type === 'Amount'
//                   ? pc[key] - Number(ad.Value)
//                   : pc[key] - (pc[key] * Number(ad.Value)) / 100;
//                 return pc;
//               }
//               if (ad.adjustment_value_direction_id === 1) {
//                 if (pc[`document_${key}`] <= 0) {
//                   pc[`document_${key}`] = 0;
//                   return pc;
//                 }
//                 pc[`document_${key}`] = pc[`document_${key}`]
//                   ? ad.Type === 'Amount'
//                     ? pc[`document_${key}`] + Number(ad.Value)
//                     : pc[`document_${key}`] + (pc[key] * Number(ad.Value)) / 100
//                   : ad.Type === 'Amount'
//                   ? pc[key] + Number(ad.Value)
//                   : pc[key] + (pc[key] * Number(ad.Value)) / 100;
//                 return pc;
//               }
//             }
//             return pc;
//           }
//           return pc;
//         });
//       }
//     });
//     return newProductServices;
//   }

//   return productServices;
// }
function addLocation(productServices) {
  const locations = [];
  productServices.map((pc) => {
    if (pc.location && !locations.some((lc) => lc.code === pc.location)) {
      locations.push({ name: pc.location, code: pc.location });
    }
  });
  return locations;
}

function getProductServiceItemIds(layout) {
  const productServiceItemIds = [];
  layout.map((item) => {
    if (item?.typeId === "product_service") {
      productServiceItemIds.push(item.i);
    }
  });
  return productServiceItemIds;
}

function calculateDocumentTotalInLink(productServices) {
  const total = {
    taxableSubTotal: 0,
    nonTaxableSubTotal: 0
  };

  const newProductServices = productServices.map((pc) => {
    const newPC = { ...pc };
    const isOptionalChecked = pc.optional ? pc.optional_is_selected : true;
    if (newPC.taxable) {
      newPC.total = isOptionalChecked ? +newPC.sell * +newPC.quantity - +newPC.discount : 0;
      if (!newPC.recurrence_include_in_total || isOptionalChecked) {
        total.taxableSubTotal += Number(newPC.total);
      }
      return newPC;
    }

    newPC.total = isOptionalChecked ? +newPC.sell * +newPC.quantity - +newPC.discount : 0;
    if (!newPC.recurrence_include_in_total || isOptionalChecked) {
      total.nonTaxableSubTotal += Number(newPC.total);
    }
    return newPC;
  });

  return { newProductServices, total };
}

const ZERO = 0;
const _documentTotalConst = {
  pre_adjusted_subtotal: ZERO,
  sub_total: ZERO,
  one_time_subtotal: ZERO,
  monthly_subtotal: ZERO,
  bi_monthly_subtotal: ZERO,
  quarterly_subtotal: ZERO,
  semi_annual_subtotal: ZERO,
  annual_subtotal: ZERO,
  adjustment_amount: ZERO,
  sales_tax: ZERO,
  sales_tax_rate: ZERO,
  grand_total: ZERO,
  document_cost: ZERO,
  shipping: ZERO,
  shipping_tax: true
};
export const documentSettingsSlice = createSlice({
  name: "document",
  initialState: {
    selectedDocumentId: null,
    isFetched: false,
    columnNames: [],
    documents: [],
    documents_task: [],
    count: 0,
    pagination: {
      skip: 0,
      limit: 10,
      sortInfo: [],
      groupBy: [],
      filterValue: []
    },
    mergeFields: {
      isLoading: false,
      isLoaded: false,
      allMergeFields: [],
      data: [],
      error: null
    },

    contactsSelectSearch: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: null
    },

    companiesSelectSearch: {
      isLoading: false,
      isLoaded: false,
      data: [],
      error: null
    },

    shipping: {
      isLoading: false,
      isLoaded: false,
      data: {},
      error: undefined
    },

    // single document
    document: {
      isFetchedHead: false,
      isFetchedItem: false,
      isLoadingHead: false,
      isLoading: false,
      isReadOnly: false,
      isCopy: false,
      data: {
        head: undefined,
        item: undefined
      },
      productServices: {
        isEdit: false,
        productServiceItemIds: [],
        list: [],
        _list: []
      }
    },
    inventory: {
      isLoading: false,
      isFetched: false,
      list: []
    },
    productLookup: {
      loadingText: "",
      list: []
    },
    documentTotal: {
      taxableSubTotal: 0,
      nonTaxableSubTotal: 0,
      subTotal: 0,
      adjustedTaxableSubTotal: 0,
      adjustedNonTaxableSubTotal: 0,
      adjustedCost: 0,
      adjustedSell: 0,
      adjustedSubTotal: 0,
      nonAdjustedTaxableSubTotal: 0,
      nonAdjustedFixedSubTotal: 0,
      nonAdjustedRecurringSubTotal: 0,
      nonAdjustedSubTotal: 0,
      adjustedFixedSubTotal: 0,
      adjustedRecurringSubTotal: 0,
      adjustedRecurringSubTotalReference: 0,
      showOneTimeSubTotal: false
    },
    _documentTotal: _documentTotalConst,

    totalCost: 0,

    tenantSettings: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },

    documentNotes: {
      isFetched: false,
      data: {},
      columnNames: []
    },

    documentTasks: {
      isFetched: false,
      data: {},
      isLoading: false,
      columnNames: []
    },

    documentRevisions: {
      isFetched: false,
      data: {},
      columnNames: [],
      isLoading: false
    },

    selectedSignatures: [],

    status: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    type: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    template: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    documentLocations: [],
    documentManufacturerIds: [],
    allDocumentAttributes: {
      isFetched: false
    },
    adjustment_source: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    adjustment_destination: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    payment_term: {
      isFetched: false,
      isLoading: false,
      list: [],
      columnNames: [],
      attributeData: {
        isFetched: false,
        isLoading: false,
        list: []
      }
    },
    documentProductServiceSearch: {
      isFetched: false,
      isLoading: false,
      pagination: {
        skip: 0,
        limit: 0
      },
      list: [],
      totalCount: 0,
      columnNames: [],
      inStock: false
    },
    outsourceProductService: {
      isFetched: false,
      isLoading: false,
      product: undefined
    },
    isLayoutChange: null
  },
  reducers: {
    onGetContactsSelectSearch(state) {
      state.contactsSelectSearch.isLoading = true;
    },

    onGetContactsSelectSearchSuccess(state, { payload }) {
      state.contactsSelectSearch.isLoading = false;
      state.contactsSelectSearch.isLoaded = true;
      state.contactsSelectSearch.data = payload;
    },

    onGetContactsSelectSearchFailure(state, { payload }) {
      state.contactsSelectSearch.isLoading = false;
      state.contactsSelectSearch.isLoaded = true;
      state.contactsSelectSearch.data = [];
      state.contactsSelectSearch.error = payload;
    },

    onGetCompaniesSelectSearch(state) {
      state.companiesSelectSearch.isLoading = true;
    },

    onGetCompaniesSelectSearchSuccess(state, { payload }) {
      state.companiesSelectSearch.isLoading = false;
      state.companiesSelectSearch.isLoaded = true;
      state.companiesSelectSearch.data = payload;
    },

    onGetCompaniesSelectSearchFailure(state, { payload }) {
      state.companiesSelectSearch.isLoading = false;
      state.companiesSelectSearch.isLoaded = true;
      state.companiesSelectSearch.data = [];
      state.companiesSelectSearch.error = payload;
    },

    onReceiveLookupNewMessage(state, { payload }) {
      state.productLookup.loadingText = payload;
    },
    onReceiveLookupNewData(state, { payload }) {
      const currentList = cloneDeep(state.productLookup.list);
      console.log(currentList, "this is current list");
      state.productLookup.list = [...currentList, payload.data];
    },
    resetLookupData(state) {
      state.productLookup.list = [];
      state.productLookup.loadingText = "";
    },
    onLoadMergeFields(state) {
      state.mergeFields.isLoading = true;
    },
    onSuccessMergeFields(state, { payload }) {
      const currentAllMergeFields = cloneDeep(state.mergeFields.allMergeFields);
      state.mergeFields.data = payload.items;
      state.mergeFields.allMergeFields = [...currentAllMergeFields, ...payload.items];
      state.mergeFields.isLoading = false;
      state.mergeFields.isLoaded = true;
    },
    onFailedMergeFields(state, { payload }) {
      state.mergeFields.error = payload;
      state.mergeFields.isLoading = false;
      state.mergeFields.isLoaded = true;
    },
    resetMergeFields(state) {
      state.mergeFields.allMergeFields = [];
      state.mergeFields.data = [];
    },

    onPdfImport: (state, { payload }) => {
      const currentList = _.cloneDeep(state.document.productServices._list);
      const docHead = _.cloneDeep(state.document.data.head);
      const newProductServices = payload.data.product?.map((p) => {
        return {
          ...payload.payload.newPs,
          vendor_id: null,
          vendor_name: null,
          ...p,
          cost: p.cost ? Number(p.cost) : 0,
          document_item_product_service_id: uuidv4(),
          document_item_id: payload.payload.productServiceId,
          document_item_product_service_parent_id: null,
          tenant_id: docHead.tenant_id
        };
      });
      state.document.productServices._list = [...currentList, ...newProductServices];
      docHead.shipping = (Number(payload.data.shipping) || 0) + (Number(docHead.shipping) || 0) || null;
    },
    onShippingEditCall: (state) => {
      state.shipping.isLoading = true;
    },
    onShippingEditSuccess: (state, { payload }) => {
      state.shipping.isLoading = false;
      state.shipping.isLoaded = true;
      state.shipping.data = payload;
      state.document.data.head = { ...state.document.data.head, ...payload };
    },
    onShippingEditFailure: (state, { payload }) => {
      state.shipping.isLoading = false;
      state.shipping.isLoaded = true;
      state.shipping.error = payload;
    },
    onLayoutChange: (state, { payload: { payload } }) => {
      state.isLayoutChange = payload;
    },
    onCallOutsourceProductService: (state) => {
      state.outsourceProductService.isFetched = false;
      state.outsourceProductService.isLoading = true;
    },
    onSuccessOutsourceProductService: (state, { payload }) => {
      state.outsourceProductService.isFetched = true;
      state.outsourceProductService.isLoading = false;
      state.outsourceProductService.product = payload;
    },
    onFailedOutsourceProductService: (state) => {
      state.documentProductServiceSearch.isLoading = false;
      state.documentProductServiceSearch.isFetched = true;
    },
    onCallDocumentProductServicesSearch: (state, { payload }) => {
      state.documentProductServiceSearch.pagination = {
        skip: payload?.skip || 0,
        limit: payload?.limit || 10
      };
      state.documentProductServiceSearch.isLoading = true;
      state.documentProductServiceSearch.isFetched = false;
      state.documentProductServiceSearch.columnNames = [];
      state.documentProductServiceSearch.count = 0;
      state.documentProductServiceSearch.list = [];
    },
    onCallDocumentProductServicesSearchPromise: (state, { payload }) => {
      state.documentProductServiceSearch.pagination = {
        skip: payload?.skip || 0,
        limit: payload?.limit || 10
      };
    },
    onSuccessDocumentProductServicesSearchPromise: (state, { payload }) => {
      if (state.documentProductServiceSearch.inStock !== payload.in_stock) {
        state.documentProductServiceSearch.list = [...payload.data.tableData];
        state.documentProductServiceSearch.totalCount = parseInt(payload.data.total_count, 10) || 0;
        state.documentProductServiceSearch.columnNames = payload.data.columnNames;
        state.documentProductServiceSearch.inStock = payload.in_stock;
      } else {
        state.documentProductServiceSearch.list = [
          ...state.documentProductServiceSearch.list,
          ...payload.data.tableData
        ];
        state.documentProductServiceSearch.totalCount = parseInt(payload.data.total_count, 10) || 0;
        state.documentProductServiceSearch.columnNames = payload.data.columnNames;
      }
    },
    resetDocumentProductServicesSearch: (state) => {
      state.documentProductServiceSearch.isFetched = false;
      state.documentProductServiceSearch.columnNames = [];
      state.documentProductServiceSearch.isLoading = false;
      state.documentProductServiceSearch.count = 0;
      state.documentProductServiceSearch.list = [];
      state.documentProductServiceSearch.totalCount = 0;
    },
    onSuccessDocumentProductServicesSearch: (state, { payload }) => {
      state.documentProductServiceSearch.isLoading = false;
      state.documentProductServiceSearch.isFetched = true;
      state.documentProductServiceSearch.list = payload.tableData;
      state.documentProductServiceSearch.totalCount = parseInt(payload.total_count, 10) || 0;
      state.documentProductServiceSearch.columnNames = payload.columnNames;
    },
    onFailedDocumentProductServicesSearch: (state) => {
      state.documentProductServiceSearch.isLoading = false;
      state.documentProductServiceSearch.isFetched = true;
      state.documentProductServiceSearch.totalCount = 0;
      state.documentProductServiceSearch.list = [];
      state.documentProductServiceSearch.columnNames = [];
    },
    onCallDocumentProductServiceCheckInventory: (state) => {
      state.inventory.isLoading = true;
      // state.inventory.list =
    },
    onSuccessDocumentProductServiceCheckInventory: (state, { payload }) => {
      state.inventory.isLoading = false;
      state.inventory.list = payload;
      state.inventory.isFetched = true;
    },
    onFailedDocumentProductServiceCheckInventory: (state) => {
      state.inventory.isLoading = false;
      state.inventory.isFetched = true;
      // state.documentProductServiceSearch.inventory.list =
    },
    onResetDocumentProductServiceInventory: (state) => {
      state.inventory.isLoading = false;
      state.inventory.list = [];
      state.inventory.isFetched = false;
    },
    // all documents
    onCallDocuments: (state, { payload }) => {
      if (!payload) {
        // initial call
        state.isFetched = false;
        state.columnNames = [];
        state.count = 0;
      } else {
        // with pagination
        state.pagination = payload;
      }
      state.documents = [];
    },
    onSuccessDocuments: (state, { payload }) => {
      state.isFetched = true;
      state.count = parseInt(payload?.total_count, 10);
      state.documents = payload.tableData;
      state.columnNames = payload.columnNames;
    },
    onSuccessDocumentsTask: (state, { payload }) => {
      state.isFetched = true;
      state.documents_task = payload;
    },
    onFailedDocumentsTask: (state) => {
      state.isFetched = true;
      state.documents_task = [];
    },
    onFailedDocuments: (state) => {
      state.isFetched = true;
      state.documents = [];
      state.columnNames = [];
      state.count = 0;
    },

    switchDocumentsLoading: (state, { payload }) => {
      state.document.isLoading = payload !== undefined ? payload : !state.document.isLoading;
    },

    updateDocumentHead: (state, { payload }) => {
      const currentHead = cloneDeep(state.document.data.head);
      state.document.data = {
        item: state.document.data.item,
        head: {
          ...currentHead,
          shipping: payload.shipping,
          shipping_tax: payload.shipping_tax
        }
      };
    },

    onSuccessDocumentHead: (state, { payload: { headData } }) => {
      state.document.isFetchedHead = true;
      state.document.isLoading = false;
      state.document.data = {
        item: state.document.data.item,
        head: headData?.document
      };
      if (state.document?.productServices?._list?.length) {
        const _headData = state.document.data.head;
        const productServices = state.document.productServices._list;
        const calc = calculateAll(_headData, productServices, ["currency_id", "currency"]);
        state._documentTotal = calc;
      }
    },
    onSuccessDocumentHeadWithChangedFields: (state, { payload: { headData, fields } }) => {
      state.document.isFetchedHead = true;
      state.document.isLoading = false;
      state.document.data = {
        item: state.document.data.item,
        head: { ...headData?.document, ...fields, template_document_number: headData?.document.document_number }
      };
    },

    onUpdateDocumentItemFromTemplate: (state, { payload }) => {
      const clonedDocument = cloneDeep(state.document);
      const deletedItemIds = clonedDocument?.data.item?.layoutData?.layout?.map((item) => item.i);

      const deletedProducts = clonedDocument.productServices?._list.map(
        (item) => item.document_item_product_service_id
      );
      state.document.data = {
        item: {
          ...clonedDocument.data.item,
          layoutData: { ...payload.layoutData, deletedItemIds, deletedProducts },
          productServices: payload.productServices,
          sales_tax_rate: payload.sales_tax_rate,
          tableData: payload.tableData
        },
        head: state.document.data.head
      };

      const newProductServices = payload.productServices.map((dps) => parseDocumentProductService(dps));
      state.document.productServices.productServiceItemIds = getProductServiceItemIds(payload.layoutData.layout);
      if (payload.productServices.length) {
        state.documentLocations = addLocation(payload.productServices);
        state.documentManufacturerIds = addManufacturer(payload.productServices);
        const adjustedProductServices = calculateAdjustments(
          newProductServices,
          state.document.productServices.productServiceItemIds,
          payload.tableData ?? []
        );
        state.document.productServices._list = adjustedProductServices;
      }
    },

    onSuccessDocumentItem: (state, { payload: { itemData } }) => {
      if (itemData?.document_items?.[0]?.document_item_read_only_data) {
        state.document.isReadOnly = true;
      }

      state.document.isFetchedItem = true;
      state.document.data = {
        // head: state.document.data.head,
        ...state.document.data,
        item: itemData
          ? {
              layoutData: itemData.layoutData,
              sales_tax_rate: itemData.sales_tax_rate,
              documentId: itemData.documentId
            }
          : {}
      };
      const newProductServices = itemData.productServices.map((dps) => parseDocumentProductService(dps));
      state.document.productServices.productServiceItemIds = getProductServiceItemIds(itemData.layoutData.layout);
      if (itemData.productServices.length) {
        state.documentLocations = addLocation(itemData.productServices);
        state.documentManufacturerIds = addManufacturer(itemData.productServices);
        const adjustedProductServices = calculateAdjustments(
          newProductServices,
          state.document.productServices.productServiceItemIds,
          itemData.tableData ?? []
        );
        state.document.productServices._list = adjustedProductServices;
      }
    },
    onFailedDocument: (state) => {
      state.document.isFetchedItem = true;
      state.document.isFetchedHead = true;
      state.document.data = {
        head: undefined,
        item: undefined
      };
    },
    resetDocumentHead: (state) => {
      state.document.isFetchedHead = false;
      state.document.data = {
        head: undefined,
        item: state.document.data.item
      };
    },
    resetDocumentItem: (state) => {
      state.document.isReadOnly = false;
      state.document.isFetchedItem = false;
      state.document.data = {
        head: state.document.data.head,
        item: undefined
      };
      state.document.productServices = {
        isEdit: false,
        productServiceItemIds: [],
        list: [],
        _list: []
      };
    },
    onCallDocumentNotes: (state, { payload }) => {
      state.documentNotes.isLoading = true;
      if (!payload) {
        state.documentNotes.isFetched = false;
      }
      state.documentNotes.columnNames = [];
      state.documentNotes.data = [];
    },
    onSuccessDocumentNotes: (state, { payload }) => {
      state.documentNotes.isFetched = true;
      state.documentNotes.data = payload.tableData;
      state.documentNotes.columnNames = payload.columnNames;
      state.documentNotes.isLoading = false;
    },
    resetDocumentNotes: (state) => {
      state.documentNotes.isFetched = false;
      state.documentNotes.data = {};
      state.documentNotes.columnNames = [];
    },
    onAddNewNote: (state, { payload }) => {
      const currentNotes = cloneDeep(state.documentNotes.data);
      state.documentNotes.data = [...payload, ...currentNotes];
    },
    onFailedDocumentNotes: (state) => {
      state.documentNotes.isFetched = true;
      state.documentNotes.data = [];
      state.documentNotes.columnNames = [];
      state.documentNotes.isLoading = false;
    },
    onCallDocumentAddNote: (state) => {
      state.documentNotes.isLoading = true;
    },
    onCallDocumentTasks: (state, payload) => {
      if (!payload) {
        state.documentTasks.isFetched = false;
      }
      state.documentTasks.isLoading = true;
      state.documentTasks.columnNames = [];
      state.documentTasks.data = [];
    },
    onSuccessDocumentTasks: (state, { payload }) => {
      state.documentTasks.isFetched = true;
      state.documentTasks.isLoading = false;
      state.documentTasks.data = payload.tableData;
      state.documentTasks.columnNames = payload.columnNames;
    },
    onFailedDocumentTasks: (state) => {
      state.documentTasks.isFetched = true;
      state.documentTasks.isLoading = false;
      state.documentTasks.data = [];
      state.documentTasks.columnNames = [];
    },
    resetDocumentTasks: (state) => {
      state.documentTasks.isFetched = false;
      state.documentTasks.data = {};
      state.documentTasks.columnNames = [];
    },
    onCallDocumentRevisions: (state, { payload }) => {
      if (!payload) {
        state.documentRevisions.isFetched = false;
      }
      state.documentRevisions.isLoading = true;
      state.documentRevisions.columnNames = [];
      state.documentRevisions.data = [];
    },
    onSuccessDocumentRevisions: (state, { payload }) => {
      state.documentRevisions.isFetched = true;
      state.documentRevisions.isLoading = false;
      state.documentRevisions.data = payload.tableData;
      state.documentRevisions.columnNames = payload.columnNames;
    },
    onFailedDocumentRevisions: (state) => {
      state.documentRevisions.isFetched = true;
      state.documentRevisions.data = [];
      state.documentRevisions.isLoading = false;
      state.documentRevisions.columnNames = [];
    },

    onSuccessDocumentAdd: (state, { payload }) => {
      if (payload) {
        state.documents.push(payload);
        state.count += 1;
      }
    },
    onSuccessDocumentEdit: (state, { payload: { data, documentData } }) => {
      if (data.document) {
        state.documents = state.documents.map((document) =>
          document.document_id === data.document.document_id ? data.document : document
        );
      }
      if (documentData) {
        state.document.data.head = {
          ...state.document.data.head,
          ...documentData,
          ...data.document,
          document_name: documentData.name
        };
      }
    },
    onSuccessDocumentApprove: (state, { payload }) => {
      if (payload) {
        state.document.data.head = {
          ...state.document.data.head,
          ...payload
        };
        state.document.isReadOnly = true;
      }
    },
    onSuccessDocumentsDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.documents = state.documents.filter((document) => !payload.includes(document.document_id));
        state.count -= 1;
      }
    },
    onSuccessDocumentRequestApproval: (state, { payload }) => {
      if (payload) {
        state.document.data.head = {
          ...state.document.data.head,
          approval_request: true
        };
      }
    },

    _onSuccessDocumentItemAdd: (state, { payload }) => {
      if (payload) {
        state.document.data.item = {
          layoutData: payload.layoutData,
          sales_tax_rate: payload.sales_tax_rate,
          documentId: payload.documentId
        };
        state.document.productServices.productServiceItemIds = getProductServiceItemIds(payload.layoutData.layout);
        state.document.productServices.isEdit = false;
        if (payload.productServices.length) {
          const locations = payload.productServices.reduce((locations, pc) => {
            if (pc.location && !locations.some((lc) => lc.code === pc.location)) {
              locations.push({
                name: pc.location,
                code: pc.location
              });
            }
            return locations;
          }, []);
          state.documentLocations = locations;
          const manufacturerIds = payload.productServices.reduce((manufacturerIds, pc) => {
            if (pc.manufacturer_id && !manufacturerIds.includes(pc.manufacturer_id, 0)) {
              manufacturerIds.push(pc.manufacturer_id);
            }
            return manufacturerIds;
          }, []);
          state.documentManufacturerIds = manufacturerIds;
          const calc = calculateAll(state.document.data.head, payload.productServices, ["currency_id", "currency"]);

          state._documentTotal = {
            ...calc
          };
          state.document.productServices._list = payload.productServices;
        } else {
          state.document.productServices._list = [];
        }
      }
    },
    onSuccessLocation: (state, { payload }) => {
      const locations = state.documentLocations.map((item) => ({ ...item, isDefault: false }));
      if (!locations.some((lc) => lc.code === payload)) {
        state.documentLocations = [...locations, { name: payload, code: payload, isDefault: true }];
      }
    },
    onSuccessDocumentItemEdit: (state, { payload }) => {
      if (payload) {
        state.document.data.item = payload.document_item;
      }
    },

    switchDocumentProductServiceLoading: (state, { payload }) => {
      state.document.productServices.isLoading =
        payload !== undefined ? payload : !state.document.productServices.isLoading;
    },

    onSuccessDocumentProductServiceAdd: (state, { payload }) => {
      if (payload) {
        const newState = [...state.document.productServices._list];
        newState.push(parseDocumentProductService(payload));
        state.document.productServices._list = newState;
      }
    },
    onSuccessEditProductServiceItems: (state, { payload }) => {
      // console.log('onSuccessEditProductServiceItems');
      const { id, type } = payload;
      switch (type) {
        case "duplicateItem":
        case "addItem": {
          state.document.productServices.productServiceItemIds = [
            ...state.document.productServices.productServiceItemIds,
            id
          ];
          break;
        }
        case "removeItem": {
          const newProductServiceItemIds = state.document.productServices.productServiceItemIds.filter(
            (itemID) => itemID !== id
          );
          state.document.productServices.productServiceItemIds = newProductServiceItemIds;
          break;
        }
        default:
          break;
      }
      const newProductServices = state.document.productServices._list.map((pc) => {
        delete pc.document_sell;
        return pc;
      });
      const calc = calculateAll(state.document.data.head, newProductServices, ["currency_id", "currency"]);
      state._documentTotal = {
        ...calc
      };
    },
    onSuccessDocumentProductServiceEdit: (state, { payload }) => {
      const { productServiceData, location, itemToDelete } = payload;

      if (itemToDelete) {
        const oldList = _.cloneDeep(state.document.productServices._list);
        const productServiceNewList = oldList.filter((pc) => pc.document_item_product_service_id !== itemToDelete);
        state.document.productServices.isEdit = true;
        const calc = calculateAll(state.document.data.head, productServiceNewList, ["currency_id", "currency"]);

        state.document.productServices._list = productServiceNewList;
        state._documentTotal = {
          ...calc
        };
        return;
      }

      const documentItemId = productServiceData?.[0]?.document_item_id;
      const productServiceOldList = state.document.productServices._list.filter(
        (pc) => pc.document_item_id !== documentItemId
      );
      const productServiceNewList = [...productServiceOldList, ...productServiceData];
      state.document.productServices.isEdit = true;
      const newList = _.cloneDeep(productServiceNewList);
      const calc = calculateAll(state.document.data.head, newList, ["currency_id", "currency"]);

      state.document.productServices._list = productServiceNewList;
      state._documentTotal = {
        ...calc
      };

      if (location) {
        const newDocumentLocation = state.documentLocations.map((lc) =>
          lc.code === location ? { ...lc, isDefault: true } : { ...lc, isDefault: false }
        );
        state.documentLocations = newDocumentLocation;
      }
    },
    onSuccessDocumentProductServicesDelete: (state, { payload }) => {
      if (payload !== undefined) {
        state.document.productServices._list = state.document.productServices._list.filter(
          (ps) => !payload.includes(ps.document_item_product_service_id)
        );
      }
    },

    onCallDocumentSettings: (state, { payload }) => {
      state[payload.key].isFetched = false;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    onSuccessDocumentSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = payload.data.tableData;
      state[payload.key].columnNames = payload.data.columnNames;
    },
    onFailedDocumentSettings: (state, { payload }) => {
      state[payload.key].isFetched = true;
      state[payload.key].list = [];
      state[payload.key].columnNames = [];
    },
    switchDocumentSettingLoading: (state, { payload }) => {
      state[payload.key].isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].isLoading;
    },

    onCallDocumentTenantSettings: (state) => {
      state.tenantSettings.isFetched = false;
      state.tenantSettings.data = [];
    },
    onSuccessDocumentTenantSettings: (state, { payload }) => {
      state.tenantSettings.isFetched = true;
      state.tenantSettings.data = payload;
    },
    onFailedDocumentTenantSettings: (state) => {
      state.tenantSettings.isFetched = true;
      state.tenantSettings.data = [];
    },
    switchDocumentTenantSettingLoading: (state) => {
      state.tenantSettings.isLoading = !state.tenantSettings.isLoading;
    },

    onSuccessDocumentSettingAdd: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default) {
          state[payload.key].list = [
            ...state[payload.key].list.map((item) => ({
              ...item,
              Default: false
            })),
            payload.data
          ];
        } else {
          state[payload.key].list = [...state[payload.key].list, payload.data];
        }
      }
    },
    onSuccessDocumentSettingEdit: (state, { payload }) => {
      if (payload.data) {
        if (payload.data.Default || payload.data["Default Value"]) {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey]
              ? payload.data
              : {
                  ...item,
                  ...(payload.data["Default Value"] ? { "Default Value": false } : { Default: false })
                }
          );
        } else {
          state[payload.key].list = state[payload.key].list.map((item) =>
            item[payload.idKey] === payload.data[payload.idKey] ? payload.data : item
          );
        }
      }
    },
    onSuccessDocumentSettingDelete: (state, { payload }) => {
      if (payload.id !== undefined) {
        state[payload.key].list = state[payload.key].list.filter((item) => item[payload.idKey] !== payload.id);
      }
    },

    // ATTRIBUTES
    onCallAllDocumentAttributes: (state) => {
      state.allDocumentAttributes.isFetched = false;
      state.status.attributeData.list = [];
      state.type.attributeData.list = [];
      state.adjustment_destination.attributeData.list = [];
      state.template.attributeData.list = [];
      state.adjustment_source.attributeData.list = [];
    },
    onSuccessAllDocumentAttributes: (state, { payload }) => {
      state.allDocumentAttributes.isFetched = true;
      Object.keys(payload).forEach((attr) => {
        if (payload[attr].length > 0) {
          const codeFieldName = Object.keys(payload[attr][0]).find((k) => k.includes("_id"));
          const nameFieldName = Object.keys(payload[attr][0]).find((k) => !k.includes("Default") && !k.includes("_id"));
          state[attr].attributeData.list = payload[attr].map((item) => ({
            code: item[codeFieldName],
            name: item[nameFieldName],
            isDefault: item.default_value,
            ...(item.position !== undefined && { position: item.position })
          }));
        } else {
          state[attr].attributeData.list = [];
        }
      });
    },
    onFailedAllDocumentAttributes: (state) => {
      state.status.attributeData.list = [];
      state.allDocumentAttributes.isFetched = true;
      state.type.attributeData.list = [];
      state.adjustment_destination.attributeData.list = [];
      state.template.attributeData.list = [];
      state.adjustment_source.attributeData.list = [];
    },
    onCallDocumentAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = false;
      state[payload.key].attributeData.isLoading = false;
      state[payload.key].attributeData.list = [];
    },
    onSuccessDocumentAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;
      if (payload.key === "template" && payload?.data[0]) {
        const codeFieldName = Object.keys(payload.data[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data[0]).find((k) => !k.includes("Default") && !k.includes("_id"));
        state[payload.key].attributeData.list = _.orderBy(
          payload.data.map((item) => ({
            code: item[codeFieldName],
            name: item[nameFieldName],
            isDefault: item.default_value,
            ...(item.position !== undefined && { position: item.position })
          })),
          [(list) => list.name.toLowerCase()],
          ["asc"]
        );
      }
      if (payload?.data?.tableData?.[0]) {
        const codeFieldName = Object.keys(payload.data.tableData[0]).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data.tableData[0]).find(
          (k) => !k.includes("Default") && !k.includes("_id")
        );

        state[payload.key].attributeData.list = payload.data.tableData.map((item) => ({
          code: item[codeFieldName],
          name: item[nameFieldName],
          isDefault: item.default_value,
          ...(item.position !== undefined && { position: item.position })
        }));
      }
    },
    onFailedDocumentAttributes: (state, { payload }) => {
      state[payload.key].attributeData.isFetched = true;
      state[payload.key].attributeData.list = [];
    },
    onSuccessDocumentAttributeAdd: (state, { payload }) => {
      if (payload.data) {
        const codeFieldName = Object.keys(payload.data).find((k) => k.includes("_id"));
        const nameFieldName = Object.keys(payload.data).find((k) => !k.includes("Default") && !k.includes("_id"));

        state[payload.key].attributeData.list = [
          ...current(state[payload.key].attributeData.list),
          {
            code: payload.data[codeFieldName],
            name: payload.data[nameFieldName]
          }
        ];
      }
    },
    switchDocumentAttributeChangeLoading: (state, { payload }) => {
      state[payload.key].attributeData.isLoading =
        payload[payload.key] !== undefined ? payload[payload.key] : !state[payload.key].attributeData.isLoading;
    },
    onSuccessDocumentAttributeChange: (state, { payload }) => {
      if (payload.data && Array.isArray(payload.data)) {
        state.documents = state.documents.map(
          (d) => payload.data.find((newD) => newD.document_id === d.document_id) || d
        );
      }
    },
    onSuccessProductServiceTaxableEdit: (state, { payload }) => {
      // console.log("onSuccessProductServiceTaxableEdit warning")
      if (payload.type === "not taxable") {
        const newProductServices = state.document?.productServices?._list.map((pc) => ({ ...pc, taxable: false }));
        state.document.productServices._list = newProductServices;
      }
      if (payload.type === "all taxable") {
        const newProductServices = state.document?.productServices?._list.map((pc) => ({ ...pc, taxable: true }));
        state.document.productServices._list = newProductServices;
      }
      if (payload.type === "part taxable") {
        const newProductServices = state.document?.productServices?._list.map((pc) => {
          if (payload.taxableIDS.includes(pc.category_id)) {
            return { ...pc, taxable: true };
          }
          return { ...pc, taxable: false };
        });
        state.document.productServices._list = newProductServices;
      }
    },
    onSuccessDocumentFromLink: (state, { payload }) => {
      // console.log('onSuccessDocumentFromLink warning');
      const { headData, productServices, itemData, documentItems, tenantData } = payload;
      state.document.data = { head: headData, itemData, documentItems, tenantData };
      const parsedProductServices = productServices.map((dps) => parseDocumentProductService(dps));
      const { newProductServices, total } = calculateDocumentTotalInLink(parsedProductServices);
      state.document.productServices._list = newProductServices;
      const subTotal = total.taxableSubTotal + total.nonTaxableSubTotal;
      // Check this one
      state.documentTotal = {
        ...state.documentTotal,
        taxableSubTotal: total.taxableSubTotal,
        nonTaxableSubTotal: total.nonTaxableSubTotal,
        subTotal
      };

      state._documentTotal = {
        ...state.documentTotal,
        taxableSubTotal: total.taxableSubTotal,
        nonTaxableSubTotal: total.nonTaxableSubTotal,
        subTotal
      };
    },
    onSuccessEditProductServiceInLink: (state, { payload }) => {
      // console.log('onSuccessEditProductServiceInLink warning');
      const { newProductServices, total } = calculateDocumentTotalInLink(payload);
      state.document.productServices._list = newProductServices;
      const subTotal = total.taxableSubTotal + total.nonTaxableSubTotal;
      // This one too
      const currentDocumentTotal = cloneDeep(state.documentTotal);
      const currentUnderscoreDocumentTotal = cloneDeep(state._documentTotal);
      state.documentTotal = {
        ...currentDocumentTotal,
        taxableSubTotal: total.taxableSubTotal,
        nonTaxableSubTotal: total.nonTaxableSubTotal,
        subTotal
      };

      state._documentTotal = {
        ...currentUnderscoreDocumentTotal,
        taxableSubTotal: total.taxableSubTotal,
        nonTaxableSubTotal: total.nonTaxableSubTotal,
        subTotal
      };
    },
    onCallSelectedSignatures: (state, { payload }) => {
      state.selectedSignatures = [...state.selectedSignatures, payload];
    },
    removeSignature: (state, { payload }) => {
      const newOptions = state.selectedSignatures.filter((i) => i.itemId !== payload);
      state.selectedSignatures = newOptions;
    },
    resetSignatures: (state) => {
      state.selectedSignatures = [];
    },
    keepSelectedDocumentId: (state, { payload }) => {
      state.selectedDocumentId = payload;
    },
    resetTemplateId: (state) => {
      state.selectedDocumentId = null;
    },
    resetDocumentTotal: (state) => {
      state._documentTotal = _documentTotalConst;
    },
    onSuccessDocumentSend: (state) => {
      state.document.isReadOnly = true;
      state.document.head = {
        ...state.document.head,
        read_only: true
      };
    },
    onCallCopyDocument: (state, { payload }) => {
      state.document.data = {
        item: payload.documentItemData,
        head: payload.documentHeadData
      };

      const newProductServices = payload.documentItemData.productServices.map((dps) =>
        parseDocumentProductService(dps)
      );
      state.document.productServices.productServiceItemIds = getProductServiceItemIds(
        payload.documentItemData.layoutData.layout
      );
      if (payload.documentItemData.productServices.length) {
        state.documentLocations = addLocation(payload.documentItemData.productServices);
        state.documentManufacturerIds = addManufacturer(payload.documentItemData.productServices);
        const adjustedProductServices = calculateAdjustments(
          newProductServices,
          state.document.productServices.productServiceItemIds,
          payload.tableData ?? []
        );
        state.document.productServices._list = adjustedProductServices;
      }

      state.document.isCopy = true;
      state.document.isReadOnly = false;
    },
    resetDocumentCopy: (state) => {
      state.document.isCopy = false;
    }
  }
});

export const {
  onShippingEditCall,
  onShippingEditSuccess,
  onShippingEditFailure,

  onGetContactsSelectSearch,
  onGetContactsSelectSearchSuccess,
  onGetContactsSelectSearchFailure,
  onGetCompaniesSelectSearch,
  onGetCompaniesSelectSearchSuccess,
  onGetCompaniesSelectSearchFailure,

  updateDocumentHead,

  onLoadMergeFields,
  onSuccessMergeFields,
  onFailedMergeFields,
  resetMergeFields,

  keepSelectedDocumentId,
  resetTemplateId,
  resetDocumentTotal,

  onLayoutChange,

  onAddNewNote,

  onCallOutsourceProductService,
  onSuccessOutsourceProductService,
  onFailedOutsourceProductService,

  // calculateAdjustmentsForPreview,

  onCallDocumentProductServicesSearch,
  onSuccessDocumentProductServicesSearch,
  onFailedDocumentProductServicesSearch,
  resetDocumentProductServicesSearch,
  onSuccessDocumentProductServicesSearchPromise,
  onCallDocumentProductServicesSearchPromise,

  onCallDocumentProductServiceCheckInventory,
  onSuccessDocumentProductServiceCheckInventory,
  onFailedDocumentProductServiceCheckInventory,
  onResetDocumentProductServiceInventory,

  onCallDocuments,
  onSuccessDocuments,
  onSuccessDocumentsTask,
  onFailedDocuments,
  onFailedDocumentsTask,
  resetDocumentTasks,
  switchDocumentsLoading,

  onSuccessDocumentHead,
  onSuccessDocumentHeadWithChangedFields,
  onUpdateDocumentItemFromTemplate,
  onSuccessDocumentItem,
  onFailedDocument,
  resetDocumentHead,
  resetDocumentItem,
  onSuccessDocumentAdd,
  onSuccessDocumentEdit,
  onSuccessDocumentApprove,
  onSuccessDocumentsDelete,
  onSuccessDocumentRequestApproval,

  onCallDocumentNotes,
  onSuccessDocumentNotes,
  onFailedDocumentNotes,

  onCallDocumentAddNote,
  onSuccessDocumentAddNote,
  onFailedDocumentAddNote,
  resetDocumentNotes,

  onCallDocumentTasks,
  onSuccessDocumentTasks,
  onFailedDocumentTasks,

  onCallDocumentRevisions,
  onSuccessDocumentRevisions,
  onFailedDocumentRevisions,

  onSuccessDocumentItemAdd,
  _onSuccessDocumentItemAdd,
  onSuccessDocumentItemEdit,

  // onCallDocumentProductServices,
  // onSuccessDocumentProductServices,
  // onFailedDocumentProductServices,

  switchDocumentProductServiceLoading,
  onSuccessDocumentProductServiceAdd,
  onSuccessDocumentProductServiceEdit,
  onSuccessDocumentProductServicesDelete,

  onCallDocumentSettings,
  onSuccessDocumentSettings,
  onFailedDocumentSettings,

  onCallDocumentTenantSettings,
  onSuccessDocumentTenantSettings,
  onFailedDocumentTenantSettings,
  switchDocumentTenantSettingLoading,

  switchDocumentSettingLoading,
  onSuccessDocumentSettingAdd,
  onSuccessDocumentSettingEdit,
  onSuccessDocumentSettingDelete,

  onFailedAllDocumentAttributes,
  onCallAllDocumentAttributes,
  onSuccessAllDocumentAttributes,

  onCallDocumentAttributes,
  onSuccessDocumentAttributes,
  onFailedDocumentAttributes,
  onSuccessDocumentAttributeAdd,
  switchDocumentAttributeChangeLoading,
  onSuccessDocumentAttributeChange,
  onSuccessLocation,
  onSuccessProductServiceTaxableEdit,
  onSuccessEditProductServiceItems,
  onSuccessDocumentFromLink,
  onSuccessEditProductServiceInLink,
  onCallSelectedSignatures,
  removeSignature,
  resetSignatures,
  onSuccessDocumentSend,
  onPdfImport,

  onCallCopyDocument,
  resetDocumentCopy,
  onReceiveLookupNewData,
  onReceiveLookupNewMessage,
  resetLookupData
} = documentSettingsSlice.actions;

export default documentSettingsSlice.reducer;
