import { useContext, useEffect } from "react";
import socketInstance from "clients/socket";
import { SocketContext } from "../../context/socketProvider";
import { useHistory } from "react-router-dom";

/**
 * This hook handle live socket sync for module
 * @param {String} socketModuleName - Module name
 * @param {Array} socketEvents - Module events list
 * @param {Array} listenOnly - Emits only 'listen' action
 */

const useSocketLiveSyncSystem = ({ socketModuleName, socketEvents = [], listenOnly, withoutTabId = true }) => {
  // const tabId = sessionStorage.getItem("tabId");
  const { reset, alreadyConnected, setAlreadyConnected } = useContext(SocketContext);
  const history = useHistory();

  const socketEventHandler = (handler) => (data) => {
    handler(data);
  };

  console.log(alreadyConnected, 'outside');
  // Connects socket room for current module
  useEffect(() => {
    if (socketModuleName) {
      if (!listenOnly) {
        if (!alreadyConnected?.[socketModuleName]) {
          setTimeout(() => {
            socketInstance.emit("join", socketModuleName);
            setAlreadyConnected(prev => {
              return {
                ...prev,
                [socketModuleName]: true
              };
            });
          }, 100);

        }
        // Connect to socket room

      }

      // Add socket event listeners
      socketEvents.forEach(({ event, handler }) => {
        socketInstance.on(`${event}${!withoutTabId ? "-" : ""}`, socketEventHandler(handler));
      });

      return () => {
        if (!listenOnly) {
          setAlreadyConnected(prev => {
            return {
              ...prev,
              [socketModuleName]: false
            };
          });
          if (socketModuleName === "company") {
            if (!history.location.pathname.includes('companies')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "contact") {
            if (!history.location.pathname.includes('contacts')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "document") {
            if (!history.location.pathname.includes('documents')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "task") {
            if (!history.location.pathname.includes('tasks')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "product_service") {
            if (!history.location.pathname.includes('product-services')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "opportunity") {
            if (!history.location.pathname.includes('opportunities')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }

          if (socketModuleName === "dashboard") {
            if (!history.location.pathname.includes('dashboard')) {
              socketInstance.emit("leave", socketModuleName);
            }
          }


          // Disconnect from socket room
          // socketInstance.emit("leave", socketModuleName);
        }

        // Remove socket event listeners
        socketEvents.forEach(({ event, handler }) => {
          socketInstance.removeListener(`${event}${!withoutTabId ? "-" : ""}`, socketEventHandler(handler));
        });
      };
    }

    return null;
  }, []);
};

export default useSocketLiveSyncSystem;
