import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import socketInstance from "clients/socket";
import { addNotification, onSuccessNotificationDelete, onSuccessNotificationsAllDelete, onSuccessNotificationSeen } from "store/notifications/slice";
import { callNotifications } from "store/notifications/sagas-actions";
import { useSocketEventListener } from "../index";
import { userDataSelector } from "../../store/user/selectors";
import { isEqual } from "lodash";

/** This hook handle notification sockets */

const useNotifications = () => {
  const dispatch = useDispatch();
  const {
    user: { tenant_user_id }
  } = useSelector(userDataSelector, isEqual);

  const onUpdateHandler = (data) => {
    dispatch(addNotification(data));
  };

  const onDeleteNotification = (data) => {
    dispatch(onSuccessNotificationDelete({
      notificationId: data?.id,
      notificationReadStatus: false
    }));
    // dispatch(callDeleteNotification(data?.id, true));
  };

  const onDeleteAllNotification = (data) => {
    dispatch(onSuccessNotificationsAllDelete());
  };

  const onReadNotification = (data) => {
    dispatch(onSuccessNotificationSeen({
      notificationId: data?.id,
      notificationReadStatus: false
    }));
  };

  const { update } = useSocketEventListener({
    socketModuleName: `new_notification`,
    socketEventName: `NEW_NOTIFICATION`,
    listenOnly: false,
    customHandler: onUpdateHandler
  });

  const { updater } = useSocketEventListener({
    socketModuleName: `new_notification`,
    socketEventName: `NOTIFICATION_DELETE_CALL`,
    listenOnly: true,
    customHandler: onDeleteNotification
  });

  const { onDelete } = useSocketEventListener({
    socketModuleName: `new_notification`,
    socketEventName: `NOTIFICATION_DELETE_CALL`,
    listenOnly: true,
    customHandler: onDeleteAllNotification
  });

  const { onRead } = useSocketEventListener({
    socketModuleName: `new_notification`,
    socketEventName: `NOTIFICATION_READ_CALL`,
    listenOnly: true,
    customHandler: onReadNotification
  });

  // Call notifications & connect notification socket
  useEffect(() => {
    dispatch(callNotifications());
  }, []);
};

export default useNotifications;
