import { all } from "redux-saga/effects";
import userWatcherSaga from "./user/sagas";
import generalSaga from "./general/sagas";
import plansWatcherSaga from "./plans/sagas";
import termsWatcherSaga from "./terms-conditions/sagas";
import appMessageWatcherSaga from "./app-message/sagas";
import industriesWatcherSaga from "./industries/sagas";
import templatesWatcherSaga from "./templates/sagas";
import organizationWatcherSaga from "./organization/sagas";
import usersWatcherSaga from "./users/sagas";
import companiesWatcherSaga from "./companies/sagas";
import contactsWatcherSaga from "./contacts/sagas";
import securityWatcherSaga from "./security/sagas";
import currenciesWatcherSaga from "./currencies/sagas";
import timezonesWatcherSaga from "./timezones/sagas";
import salesTaxWatcherSaga from "./sales-tax/sagas";
import companySettingsWatcherSaga from "./settings/company/sagas";
import contactSettingsWatcherSaga from "./settings/contact/sagas";
import opportunityWatcherSaga from "./opportunities/sagas";
import tableViewWatcherSaga from "./table-view/sagas";
import notificationsWatcherSaga from "./notifications/sagas";
import documentsWatcherSaga from "./documents/sagas";
import auditLogWatcherSaga from "./audit-log/sagas";
import workflowsWatcherSaga from "./workflows/sagas";
import documentWizardsWatcherSaga from "./document-wizard/sagas";
import productsServicesWatcherSaga from "./products-services/sagas";
import dashboardsWatcherSaga from "./dashboard/sagas";
import integrationsWatcherSaga from "./integrations/sagas";
import importWatcherSaga from "./import/sagas";
import SubuserDomainWatcherSaga from "./subuserDomain/sagas";
import tasksSaga from "./task/sagas";
import notesWatcherSaga from "./notes/sagas";
import sequencesWatcherSaga from "./sequences/sagas";
import taskSettingsWatcherSaga from "./settings/task/sagas";
import adminWatcherSaga from "./admin/sagas";
import appUniversalSearchSaga from "./app-universal-search/sagas";
import messagesSaga from "./messages/sagas";
import exportSaga from "./export/sagas";
import noteMessagesSaga from "./noteMessages/sagas";
import deletedItemsWatcherSaga from "./deleted-items/sagas";
import videoTemplateWatcherSaga from "./video/sagas";
import impersonateWatcherSaga from "./impersonate/sagas";
import permissionsSaga from "./settings/permissions/sagas";

export default function* rootSaga() {
  yield all([
    importWatcherSaga(),
    permissionsSaga(),
    sequencesWatcherSaga(),
    appUniversalSearchSaga(),
    notesWatcherSaga(),
    taskSettingsWatcherSaga(),
    tasksSaga(),
    userWatcherSaga(),
    SubuserDomainWatcherSaga(),
    generalSaga(),
    plansWatcherSaga(),
    termsWatcherSaga(),
    appMessageWatcherSaga(),
    industriesWatcherSaga(),
    templatesWatcherSaga(),
    organizationWatcherSaga(),
    usersWatcherSaga(),
    companiesWatcherSaga(),
    contactsWatcherSaga(),
    securityWatcherSaga(),
    currenciesWatcherSaga(),
    timezonesWatcherSaga(),
    salesTaxWatcherSaga(),
    companySettingsWatcherSaga(),
    contactSettingsWatcherSaga(),
    opportunityWatcherSaga(),
    tableViewWatcherSaga(),
    notificationsWatcherSaga(),
    documentsWatcherSaga(),
    auditLogWatcherSaga(),
    workflowsWatcherSaga(),
    productsServicesWatcherSaga(),
    dashboardsWatcherSaga(),
    integrationsWatcherSaga(),
    adminWatcherSaga(),
    messagesSaga(),
    exportSaga(),
    documentWizardsWatcherSaga(),
    noteMessagesSaga(),
    deletedItemsWatcherSaga(),
    videoTemplateWatcherSaga(),
    impersonateWatcherSaga()
  ]);
}
