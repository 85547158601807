import { createSlice } from "@reduxjs/toolkit";
import { AZURE_STORAGE_PREFIX, DIGITALOCEAN_SPACES_STORAGE_URL } from "constant/env-variables";
import _ from "lodash";
import baseThemeProps from "../../styles/theme";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isGetLoading: !!localStorage.getItem("userToken"),
    isLoading: false,
    isError: undefined,
    user: undefined,
    userEmailData: {
      isFetched: false,
      isLoading: false,
      data: undefined
    },
    userEULA: {
      isFetched: false,
      eulaAcceptance: false
    },
    factor: {
      authType: "totp"
    },
    userToken: undefined,
    passwordResetStatus: undefined,

    // User UI settings
    themeMode: true,
    themeProps: baseThemeProps.default,

    // Temporary ui settings
    tempUiSettings: {}
  },
  reducers: {
    onTableViewChange: (state, { payload }) => {
      const currentState = _.cloneDeep(state);
      state.user = {
        ...currentState.user,
        ...payload
      };
    },
    onCallUserEULA: (state) => {
      state.userEULA.isFetched = false;
    },
    onSuccessUserEULA: (state, { payload }) => {
      state.userEULA.eulaAcceptance = payload.EULAceptance;
      state.userEULA.isFetched = true;
      state.user.eula_acceptance_date = payload.EULAceptance;
    },
    onFailedUserEULA: (state) => {
      state.userEULA.isFetched = true;
    },
    setUserSignFactor: (state, { payload }) => {
      const { authType } = payload;

      if (authType === "totp") {
        const { factor } = payload;
        const { binding, sid, identity, status } = factor;

        state.factor = {
          authType,
          entityId: identity,
          factorSid: sid,
          status,
          ...(binding && { binding })
        };
      }

      if (authType === "sms") {
        const { entityId } = payload;

        state.factor = {
          authType,
          entityId
        };
      }
    },
    setUserToken: (state, { payload }) => {
      if (payload) {
        state.factor = {};
        state.userToken = payload;
        localStorage.setItem("userToken", payload);
      }
    },
    setUserTabId: (state, { payload }) => {
      if (payload) {
        sessionStorage.setItem("tabId", payload);
      }
    },
    setUserFactorStatus: (state, { payload }) => {
      if (payload?.status) {
        state.factor.status = payload.status;
      }
    },
    setUserLocal: (state, { payload }) => {
      if (payload) {
        state.user = {
          ...payload,
          ...(payload?.photo && {
            photo: `${AZURE_STORAGE_PREFIX || DIGITALOCEAN_SPACES_STORAGE_URL}/${payload.tenant_id}/${
              payload.tenant_user_id
            }/${payload.photo}`
          })
        };

        if (payload.theme_mode !== undefined) {
          state.themeMode = payload.theme_mode;
          state.themeProps = {
            primary: {
              light: `#${payload.theme_color_light}`,
              main: `#${payload.theme_color_main}`,
              dark: `#${payload.theme_color_dark}`,
              contrastText: "#fff"
            }
          };
        }
      }
    },
    userGetLoadingSwitch: (state, { payload }) => {
      state.isGetLoading = payload !== undefined ? payload : !state.isGetLoading;
    },
    updateUserLocalProps: (state, { payload }) => {
      if (payload) {
        state.user = {
          ...state.user,
          ...payload
        };

        if (payload.ui_settings.theme_mode !== undefined) {
          state.themeMode = payload.ui_settings.theme_mode;
          state.themeProps = {
            primary: {
              light: `#${payload.ui_settings.theme_color_light}`,
              main: `#${payload.ui_settings.theme_color_main}`,
              dark: `#${payload.ui_settings.theme_color_dark}`,
              contrastText: "#fff"
            }
          };
        }
      }
    },
    updateUserTemporaryUiSettings: (state, { payload }) => {
      if (!payload) {
        state.tempUiSettings = {};
      } else {
        state.tempUiSettings.themeMode = payload?.theme_mode;
        state.tempUiSettings.themeProps = {
          primary: {
            light: `#${payload?.theme_color_light}`,
            main: `#${payload?.theme_color_main}`,
            dark: `#${payload?.theme_color_dark}`,
            contrastText: "#fff"
          }
        };
      }
    },
    setUserResetPasswordStatus: (state, { payload }) => {
      state.passwordResetStatus = payload;
    },
    resetUserData: (state) => {
      state.isGetLoading = false;
      state.isLoading = false;
      state.user = undefined;
      state.factor = {
        authType: "totp"
      };
      state.userToken = undefined;
      state.passwordResetStatus = undefined;
      localStorage.removeItem("userToken");
    },
    onUserError: (state, { payload }) => {
      function getErrorMessage(error) {
        // TODO -> for custom messages
        // switch (error?.status) {
        //     case 401:
        //         return 'Wrong credentials';
        //     case 403:
        //         return 'Please try again after 5 minutes';
        //     case 404:
        //         return 'No user found with this email address';
        //     case 409:
        //         return 'Wrong code';
        //     default:
        //         return 'Unknown error';
        // }
        return error?.msg || error?.message || "There is a server issue. Please contact support.";
      }

      state.isError = getErrorMessage(payload);
    },
    removeUserError: (state) => {
      state.isError = undefined;
    },
    userLoadingSwitch: (state, { payload }) => {
      state.isLoading = payload !== undefined ? payload : !state.isLoading;
    },
    changeTheme: (state, { payload }) => {
      state.themeMode = payload;
    },
    changeThemeProps: (state, { payload }) => {
      if (payload) {
        state.themeProps = payload;
      } else {
        state.themeProps = baseThemeProps.default;
      }
    },
    switchUserEmailLoading: (state, { payload }) => {
      state.userEmailData.isLoading = payload !== undefined ? payload : !state.userEmailData.isLoading;
    },
    setUserEmail: (state, { payload }) => {
      if (payload && typeof payload?.type === "number") {
        state.userEmailData.data = payload;
        state.userEmailData.isFetched = true;
      }
    },
    changeUserEmail: (state, { payload }) => {
      if (payload) {
        state.userEmailData.data = {
          ...state.userEmailData.data,
          ...payload
        };
      }
    },
    removeUserEmail: (state) => {
      state.userEmailData.data = undefined;
    },
    setUserEmailError: (state, { payload }) => {
      state.userEmailData.isFetched = true;
      state.userEmailData.error = payload || '';
    },
    onSuccessMeUpdate: (state, { payload }) => {
      if (payload) {
        state.user = {
          ...state.user,
          ...payload
        };
      }
    }
  }
});

export const {
  onCallUserEULA,
  onSuccessUserEULA,
  onFailedUserEULA,
  setUserSignFactor,
  setUserToken,
  setUserFactorStatus,
  setUserLocal,
  userGetLoadingSwitch,
  updateUserLocalProps,
  updateUserTemporaryUiSettings,
  userLoadingSwitch,
  resetUserData,
  onUserError,
  removeUserError,
  setUserResetPasswordStatus,
  changeTheme,
  changeThemeProps,
  switchUserEmailLoading,
  setUserEmail,
  setUserEmailError,
  changeUserEmail,
  removeUserEmail,
  setUserTabId,
  onSuccessMeUpdate,
  onTableViewChange
} = userSlice.actions;

export default userSlice.reducer;
