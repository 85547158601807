import { alpha } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale"
      },
      a: {
        textDecoration: "none"
      },
      "div[data-lastpass-icon-root=true]": {
        display: "none!important"
      },
      ".tox-tinymce-aux": {
        zIndex: "1305!important",
        "& > div": {
          "& > .tox-toolbar__overflow": {},
          "& > .tox-editor-container": {
            "& > .tox-editor-header": {
              "& > .tox-toolbar-overlord": {
                backgroundColor: "transparent!important"
              }
            }
          },
        }, 
        "& > .tox-dialog-wrap > .tox-dialog--width-md": {
          height: '500px',
          "& .tox-textarea-wrap:focus-within, .tox-textarea:focus, .tox-textfield:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.5)}`
          },
          "& .tox-dialog__footer": {
            "& .tox-button": {
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main
            },
            "& .tox-button.tox-button--secondary": {
              backgroundColor: theme.palette.grey[100],
              borderColor: theme.palette.grey[100]
            }
          }
        }
      },
      // TODO -> create new dialog component with overrides
      ".MuiDialogContent-root": {
        padding: `${theme.spacing(2, 3)}!important`
      },
      ".MuiDialogActions-root": {
        padding: `${theme.spacing(1, 3)}!important`
      },
      ".color-picker-button": {
        display: "flex",
        alignItems: "center",

        "& > div": {
          minWidth: "40px",
          minHeight: "25px",
          border: `3px solid ${theme.palette.primary.main}`,
          marginRight: theme.spacing(1.5)
        }
      },
      dropzone: {
        backgroundColor: ({ applyDarkModeStyles }) => applyDarkModeStyles && theme.palette.mode === "dark" && "#121212",
        color: ({ applyDarkModeStyles }) => applyDarkModeStyles && theme.palette.mode === "dark" && "white",
        backgroundImage: ({ applyDarkModeStyles }) =>
          applyDarkModeStyles &&
          theme.palette.mode === "dark" &&
          "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
        position: "relative",
        outline: "none",
        border: `1px solid ${theme.palette.mode === "dark" ? "#606060" : "#c0c0c0"}`,
        borderRadius: "4px",
        minHeight: "100px",
        padding: theme.spacing(1.5),
        "& > .drop-text": {
          position: "absolute",
          zIndex: "1",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          // opacity: 0.5,
          textAlign: "center"
        }
      }
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
