const emailTypes = [
  {
    code: 1,
    name: "Microsoft",
    url: "auth/microsoft",
    meetingAppText: "Teams Meeting"
  },
  {
    code: 2,
    name: "Gmail",
    url: "auth/google",
    meetingAppText: "Google Meet"
  }
];

export default emailTypes;
