import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";

import { GET_PERMISSIONS } from "./sagas-actions";
import { onCallGetPermissions, onCallGetPermissionsFailure, onCallGetPermissionsSuccess } from "./slice";

function* getPermissionsGenerator() {
  yield put(onCallGetPermissions());
  try {
    const { data } = yield call(axiosInstance.get, "/auth/permissions");
    yield put(onCallGetPermissionsSuccess(data));
  } catch (e) {
    yield put(onCallGetPermissionsFailure(e));
  }
}


export default function* permissionsSaga() {
  yield takeEvery(GET_PERMISSIONS, getPermissionsGenerator);
}
