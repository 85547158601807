import { combineReducers } from "redux";
import errorReducer from "store/error/slice";
import userReducer from "store/user/slice";
import generalReducer from "store/general/slice";
import plansReducer from "store/plans/slice";
import termsReducer from "store/terms-conditions/slice";
import appMessageReducer from "store/app-message/slice";
import industriesReducer from "store/industries/slice";
import templatesReducer from "store/templates/slice";
import organizationReducer from "store/organization/slice";
import usersReducer from "store/users/slice";
import companiesReducer from "store/companies/slice";
import contactsReducer from "store/contacts/slice";
import securityReducer from "store/security/slice";
import currenciesReducer from "store/currencies/slice";
import timezonesReducer from "store/timezones/slice";
import salesTaxReducer from "store/sales-tax/slice";
import opportunityReducer from "store/opportunities/slice";
import companySettingsReducer from "store/settings/company/slice";
import contactSettingsReducer from "store/settings/contact/slice";
import tableViewReducer from "store/table-view/slice";
import notificationsReducer from "store/notifications/slice";
import documentReducer from "store/documents/slice";
import auditLogsReducer from "store/audit-log/slice";
import workflowsReducer from "store/workflows/slice";
import documentWizardsReducer from "store/document-wizard/slice";
import productsServicesReducer from "store/products-services/slice";
import dashboardsReducer from "store/dashboard/slice";
import integrationsReducer from "store/integrations/slice";
import importReducer from "store/import/slice";
import subuserDomainsReducer from "store/subuserDomain/slice";
import tasksReducer from "store/task/slice";
import taskSettingsReducer from "store/settings/task/slice";
import notesReducer from "store/notes/slice";
import sequencesReducer from "store/sequences/slice";
import adminReducer from "store/admin/slice";
import appUniversalSearchReducer from "store/app-universal-search/slice";
import messagesReducer from "store/messages/slice";
import exportReducer from "store/export/slice";
import noteMessagesReducer from "store/noteMessages/slice";
import deletedItemsReducer from "store/deleted-items/slice";
import videoTemplateReducer from "store/video/slice";
import impersonateReducer from "store/impersonate/slice";

const allReducers = combineReducers({
  adminData: adminReducer,
  appUniversalSearchData: appUniversalSearchReducer,
  importData: importReducer,
  sequencesData: sequencesReducer,
  notesData: notesReducer,
  taskSettingsData: taskSettingsReducer,
  errorData: errorReducer,
  taskData: tasksReducer,
  userData: userReducer,
  subuserDomainData: subuserDomainsReducer,
  generalData: generalReducer,
  planData: plansReducer,
  termsData: termsReducer,
  appMessageData: appMessageReducer,
  industriesData: industriesReducer,
  templatesData: templatesReducer,
  organizationData: organizationReducer,
  usersData: usersReducer,
  companiesData: companiesReducer,
  contactsData: contactsReducer,
  securityData: securityReducer,
  currenciesData: currenciesReducer,
  timezonesData: timezonesReducer,
  salesTaxData: salesTaxReducer,
  companySettingsData: companySettingsReducer,
  contactSettingsData: contactSettingsReducer,
  opportunityData: opportunityReducer,
  tableViewData: tableViewReducer,
  notificationsData: notificationsReducer,
  documentData: documentReducer,
  auditLogsData: auditLogsReducer,
  workflowsData: workflowsReducer,
  documentWizardDefinitionsData: documentWizardsReducer,
  productsServicesData: productsServicesReducer,
  dashboardsData: dashboardsReducer,
  integrationsData: integrationsReducer,
  messagesData: messagesReducer,
  exportData: exportReducer,
  noteMessages: noteMessagesReducer,
  deletedItemsData: deletedItemsReducer,
  videoTemplateData: videoTemplateReducer,
  impersonateData: impersonateReducer
});

const rootReducer = (state, action) => {
  if (action.type === "user/resetUserData") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
