import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import store from "store";
import { backendDateFormat, MMM_D_YYYY_HH_MM } from "constant/dates";
import axios from "axios";
import getCroppedImg from "components/common/image-crop/crop-image";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeEachWord = (inputString) => {
  const words = inputString.split(/\b/);

  for (let i = 0; i < words.length; i++) {
    if (/^[a-zA-Z0-9]+$/.test(words[i])) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
  }

  return words.join('');
};

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};


export function chunkArray(myArray, chunkSize) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
}

export function getChargeAmount(initialAmount) {
  const newInitialAmount = Number((initialAmount / 100).toFixed(2));
  const date = new Date();
  const daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const oneDayCost = newInitialAmount / daysInCurrentMonth;
  return (newInitialAmount - (oneDayCost * date.getDate()).toFixed(2)) * 100;
}

export function toNormalPrice(price) {
  return Number(price / 100).toFixed(2);
}

export function getBodyTagFromHtml(html) {
  const bodyTag = html.match(/\<body[^>]*\>([^]*)\<\/body/m);
  return bodyTag ? bodyTag[1] : "";
}

export function getNameFirstLetters(firstName, lastName) {
  const fL = firstName ? firstName.charAt(0) : "";
  const lL = lastName ? lastName.charAt(0) : "";

  return `${fL}${lL}`;
}

export function formatDate(d, inFormat = backendDateFormat, outFormat = MMM_D_YYYY_HH_MM) {
  return moment.utc(d, inFormat).format(outFormat);
}

export function formatDateWithUserTimezone(d, timezone, outFormat = MMM_D_YYYY_HH_MM, inFormat = backendDateFormat) {
  let tz = timezone;
  if (!tz) {
    tz = store.getState().userData?.user?.timezone;
  }
  return moment.utc(d, inFormat).tz(tz).format(outFormat);
}

export function formatDateWithServerTimezone(d, outFormat = backendDateFormat) {
  return dayjs(d).utc().format(outFormat);
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

export function textFromHtml(html) {
  return html.replace(/&nbsp;|<[^>]*>?/gim, "");
}

export function bytesToSize(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function copyToClipboard(copyMe) {
  try {
    const elem = document.createElement("textarea");
    elem.setAttribute("style", `
                position: absolute;
                z-index: -99999999;
                top: -10000px;
                left: -10000px;
                opacity: 0;
            `);
    document.body.appendChild(elem);
    elem.value = copyMe;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  } catch (err) {
    console.log(err);
  }
}

export function nFormatter(num, digits = 1) {
  const si = [{
    value: 1, symbol: ""
  }, {
    value: 1e3, symbol: "K"
  }, {
    value: 1e6, symbol: "M"
  }, {
    value: 1e9, symbol: "G"
  }, {
    value: 1e12, symbol: "T"
  }, {
    value: 1e15, symbol: "P"
  }, {
    value: 1e18, symbol: "E"
  }];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toCurrency(value, symbol = "$", decimals = 2) {
  if (decimals === 0) {
    return `${symbol}${Math.round(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return `${symbol}${parseFloat(value)
    .toFixed(decimals)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
}

export function calculatePermissionStatus(sumOfPermissions, permissionValue) {
  // eslint-disable-next-line no-bitwise
  return !!(sumOfPermissions & permissionValue);
}

export function parsePhoneForBack(str) {
  return str && str.replace(/\D/g, "");
}

export function uniquifyArrayOfObjects(array, propertyName) {
  return array.filter((thing, index, self) => index === self.findIndex((t) => t[propertyName] === thing[propertyName]));
}

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function isValidUrl(url) {
  const urlExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const urlExpRegex = new RegExp(urlExp);
  return url?.match(urlExpRegex);
}

export function generateValidUrl(url) {
  const withHttpExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const withHttpExpRegex = new RegExp(withHttpExp);
  return url?.match(withHttpExpRegex) ? url : `https://${url}`;
}

export function generateSubdomainBaseUrl(baseUrl, noSubdomainApis, apiUrl) {
  if (apiUrl && noSubdomainApis.some((api) => apiUrl.includes(api))) {
    return baseUrl;
  }

  const { host } = window.location;


  if (host.includes("localhost")) {
    const dotSplintedHost = host.split(".");
    const subdomain = dotSplintedHost[0];
    return baseUrl.replace("api", subdomain);
  }
  const dotSplintedHost = host.split(".");
  if (dotSplintedHost.length <= 2) {
    return baseUrl.replace("api.", '');
  }
  return baseUrl.replace("api", dotSplintedHost[0]);
}

export function paramParserForPagination(params) {
  if (params) {
    const { skip, limit, sortInfo, groupBy, filterValue } = params;

    /** filterValue example **/
    // [
    // name: "Company Name"
    // operator: "contains"
    // type: "string"
    // value: ""
    // ]

    /** sortInfo example **/
    // dir: 1
    // id: "Address 1"
    // name: "Address 1"
    // type: undefined

    /** groupBy example **/
    // ['Title']
    return {
      current_page: parseInt(skip / limit, 10) + 1 || 1, page_size: limit, ...(filterValue && {
        filter_fields: filterValue
          .filter(({ value }) => value !== "" && value !== null)
          .map(({ name, operator, value }) => ({
            name, operator, value
          }))
      }), ...(sortInfo && {
        sorter_fields: {
          name: sortInfo.name, dir: sortInfo.dir
        }
      }), ...(groupBy && {
        group_by: groupBy
      })
    };
  }

  return null;
}

export async function downloadFile(data, fileName) {
  const URL = window.URL || window.webkitURL;
  const url = URL.createObjectURL(new Blob([data], { type: "text/html" }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

// Remove tabindex from modal to make tinymce dropdown work
export function fixTabindexInModals() {
  const muiDialogContainer = document.getElementsByClassName("MuiDialog-container");
  Array.from(muiDialogContainer).forEach((el) => {
    el.removeAttribute("tabIndex");
  });
}

export function isArrayEqual(x, y) {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
}

export function getObjectsDifferences(a, b) {
  return _.reduce(a, (result, value, key) => (_.isEqual(value, b[key]) ? result : result.concat(key)), []);
}

export function JSONParse(str) {
  let parsedData = {};
  try {
    parsedData = JSON.parse(str);
  } catch (e) {
    return {};
  }

  return parsedData;
}

export function parseValue(obj, val) {
  let parsedData = {};
  const str = Object.values(obj).find((i) => i.includes(val));
  try {
    if (str) parsedData = JSON.parse(str);

    parsedData = {};
  } catch (e) {
    return {};
  }
  return parsedData;
}

export const getCurrentPathname = (pathname) => {
  let currentPathname = "";
  const url = pathname.split("/");
  switch (url[2]) {
    case "companies":
      currentPathname = "category/companies-1ca6gzw/";
      break;
    case "contacts":
      currentPathname = "category/contacts-1umab57";
      break;
    case "dashboard":
      currentPathname = "category/dashboard-tspn1b/";
      break;
    case "documents":
      currentPathname = "category/documentsproposals-1j87jpr/";
      break;
    case "opportunities":
      currentPathname = "category/opportunities-1xj0y7f/";
      break;
    case "products-services":
      currentPathname = "category/productsservices-1go3dg/";
      break;
    case "settings":
      currentPathname = "category/settings-1o9z6ky/";
      break;
    default:
      currentPathname = "";
  }
  return currentPathname;
};

/**
 * @description Deletes object keys from provided argument
 * @param {Object<any>} obj
 * @param {string[]} keys
 */
export const delKeys = (obj = {}, keys = []) => {
  for (const key of keys) delete obj[key];
};

export const assign = (obj = {}, props) => Object.assign(obj, props);

export const getImage = async (photoUrl) => {
  try {
    const resp = await axios.get(photoUrl, { responseType: "blob" });
    if (resp) {
      const imageBlob = resp.data;

      if (imageBlob) {
        const imageSrc = {
          file: imageBlob, name: `${Math.random().toString(36).substring(2, 9)}.jpeg`
        };

        if (imageBlob.type !== "image/jpeg") {
          const area = {
            height: 200, width: 200, x: 0, y: 0
          };
          const imageBlob = await getCroppedImg(photoUrl, area, 0);
          imageSrc.file = imageBlob;
        }

        return { src: imageSrc };
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const getSocialMediaItems = ({ facebook_url, linkedin_url, twitter_url }, socialMediasList) => {
  const socialMedias = [];

  const socialMediaUrls = {
    Facebook: facebook_url, LinkedIn: linkedin_url, Twitter: twitter_url
  };

  for (const [platform, url] of Object.entries(socialMediaUrls)) {
    if (url) {
      const foundSM = socialMediasList.find((item) => item.name === platform);
      const socialMediaItem = {
        social_media_id: foundSM.social_media_id, social_media: foundSM.name, url
      };
      socialMedias.push(socialMediaItem);
    }
  }

  return socialMedias;
};

export const getQueryParams = () => new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop)
});

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const extractMergeFields = (str) => {
  const regex = /\[([^\]]+?)\]/g;
  const mergeFields = str.match(regex) || [];

  // remove the brackets and return a new array with mergeFields
  return mergeFields.map(field => field.replace(/\[|\]/g, ""));
};

export const replaceMergeFields = (string, mergeFields) => {
  if (mergeFields) {
    for (const [key, value] of Object.entries(mergeFields)) {
      if (value !== undefined) {
        const regex = new RegExp(`\\[${key}\\]`, 'g');
        string = string?.replace(regex, value ? `<span style="display: none;">{${key}}</span> ${value}` : `[${key}]`);
      }
    }
    return string;
  }
};
export const replaceMergeFieldsWithoutHiddenMergeField = (string, mergeFields) => {
  if (mergeFields) {
    for (const [key, value] of Object.entries(mergeFields)) {
      if (value !== undefined) {
        const regex = new RegExp(`\\[${key}\\]`, 'g');
        string = string?.replace(regex, value ? `<span style="display: none;">{${key}}</span> ${value}` : `[${key}]`);
      }
    }
    return string;
  }
};


const getMergeFieldsFromHtml = (html) => {
  const regExp = /{([^}]+)}/g;
  const arrayOfMergeFields = html.match(regExp) || [];
  return arrayOfMergeFields.map(item => item.replace('{', '').replace('}', ''));
};

export const convertToMergeFields = (htmlString, mergeValues) => {

  if (htmlString) {
    const replacedMergeFields = getMergeFieldsFromHtml(htmlString);
    replacedMergeFields.forEach(item => {
      const mergeFieldIndex = htmlString.indexOf(`{${item}}`);
      const valueIndex = htmlString.indexOf(mergeValues[item]);
      if (valueIndex > mergeFieldIndex) {
        // <span style="display: none;">{Organization.User First Name}</span> Edward
        htmlString = htmlString.replace(`<span style="display: none;">{${item}}</span> ${mergeValues[item]}`, `[${item}]`);
      }
    });
    return { htmlString, replacedMergeFields };

  }
  return { htmlString:  "", replacedMergeFields:  [] };
};

export const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value.trim();
};
