import { joiResolver } from "@hookform/resolvers/joi";
import Field from "components/common/fields-v2/field-generic";
import FieldAxiosCheck from "components/common/fields-v2/field-axios-check";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useFieldArray, useForm as useReactHookForm } from "react-hook-form-v7";
import FieldUrl from "components/common/fields-v2/field-url";
import SelectWithAdd from "components/common/fields-v2/select-with-add";
import SelectWithAddSearch from "components/common/fields-v2/select-with-add-search";

/**
 *
 * @param {Object} validationSchema -
 * @param {Object} defaultValues -
 * @param {Boolean} isLoading -
 * @param {Boolean} isView -
 * @param {Boolean} shouldReset - Set to true to reset the form upon successful submit
 */

const useForm = ({ validationSchema, defaultValues, isLoading, isView, shouldReset = false }) => {
  const {
    control,
    register,
    watch,
    trigger,
    resetField,
    setValue,
    setError,
    getFieldState,
    clearErrors,
    getValues,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors, dirtyFields, touchedFields }
  } = useReactHookForm({
    mode: "onChange", // 'all' option causing problems with axios fields
    reValidateMode: "onChange", // onBlur
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true, // needed for cleaning unnecessary fields in form
    shouldUseNativeValidation: false,
    delayError: undefined,
    ...(validationSchema && {
      resolver: joiResolver(validationSchema)
    }),
    defaultValues
  });

  useEffect(() => {
    if (control._formState.isSubmitSuccessful && shouldReset) {
      reset(defaultValues);
    }
  }, [control._formState.isSubmitSuccessful]);

  const injectFormProps = useCallback(
    (props) => {
      const { name, label, disabled, ...restProps } = props;

      let cleanLabel = "";
      if (typeof label === "string") {
        cleanLabel = label;
      } else {
        cleanLabel = name.replace(/_/g, " ");
      }

      let err = get(errors, name);
      if (!err && name && name.includes(".")) {
        const parsedPath = name.substr(0, name.lastIndexOf("."));
        err = get(errors, parsedPath);
      }

      return {
        ...restProps,
        control,
        name,
        label: cleanLabel,
        error: err?.message, // get(errors, name)?.message,
        // placeholder: placeholder ?? (type === 'select' ? 'Select' : `Enter ${cleanLabel}`),
        disabled: disabled || isLoading || isView
      };
    },
    [errors, isLoading, isView]
  );

  const FormField = ({ name, ...props }) => (
    <Field
      // name={name}
      // control={control}
      {...injectFormProps({ name, ...props })}
    />
  );

  const FormFieldUrl = ({ name, icon, ...props }) => (
    <FieldUrl
      // name={name}
      // control={control}
      watch={watch}
      icon={icon}
      // field={{
      //     name: `social_media_items.${index}.url`,
      //     label: sm.name,
      //     defaultValue: ''
      // }}
      FormField={FormField}
      {...injectFormProps({ name, ...props })}
    />
  );

  const FormFieldAxiosCheck = ({ name, ...props }) => (
    <FieldAxiosCheck
      // control={control}
      watch={watch}
      trigger={trigger}
      setError={setError}
      clearErrors={clearErrors}
      isDirty={get(dirtyFields, name)}
      {...injectFormProps({ name, ...props })}
    />
  );

  const FormSelectWithAdd = (props) => (
    <SelectWithAdd
      // control={control}
      setValue={setValue}
      {...injectFormProps(props)}
    />
  );

  const FormSelectWithAddSearch = (props) => {
    return (
      <SelectWithAddSearch
        control={control}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
        {...injectFormProps(props)}
      />
    );
  };

  return {
    control,
    register,
    setError,
    watch,
    getFieldState,
    trigger,
    setValue,
    errors,
    dirtyFields,
    resetField,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    formState: {
      isDirty,
      isValid,
      dirtyFields,
      touchedFields
    },
    useFieldArray,
    FormField,
    FormFieldUrl,
    FormFieldAxiosCheck,
    FormSelectWithAdd,
    FormSelectWithAddSearch
  };
};

useForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  shouldReset: PropTypes.bool
};

useForm.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export default useForm;
