import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { paramParserForPagination } from "utils/helpers";
import { setError } from "store/error/slice";
import qs from "qs";
import {
  PRODUCTS_SERVICES_CALL,
  callProductsServicesPromise,
  PRODUCTS_SERVICE_CALL,
  PRODUCTS_SERVICE_ADD_CALL,
  PRODUCTS_SERVICE_EDIT_CALL,
  PRODUCTS_SERVICES_DELETE_CALL,
  PRODUCTS_SERVICES_RESTORE_CALL,
  PRODUCTS_SERVICES_CALL_STATUSES,
  PRODUCTS_SERVICES_CALL_TYPES,
  PRODUCTS_SERVICES_CALL_CATEGORIES,
  PRODUCTS_SERVICES_CALL_MANUFACTURERS,

  // Settings
  PRODUCTS_SERVICE_STATUSES_CALL,
  PRODUCTS_SERVICE_STATUS_ADD_CALL,
  PRODUCTS_SERVICE_STATUS_EDIT_CALL,
  PRODUCTS_SERVICE_STATUS_DELETE_CALL,
  PRODUCTS_SERVICE_TYPES_CALL,
  PRODUCTS_SERVICE_TYPE_ADD_CALL,
  PRODUCTS_SERVICE_TYPE_EDIT_CALL,
  PRODUCTS_SERVICE_TYPE_DELETE_CALL,
  PRODUCTS_SERVICE_CATEGORIES_CALL,
  PRODUCTS_SERVICE_CATEGORY_ADD_CALL,
  PRODUCTS_SERVICE_CATEGORY_EDIT_CALL,
  PRODUCTS_SERVICE_CATEGORY_DELETE_CALL,
  PRODUCTS_SERVICE_MANUFACTURERS_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL,
  PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL,
  PRODUCTS_SERVICE_UOMS_CALL,
  PRODUCTS_SERVICE_UOM_ADD_CALL,
  PRODUCTS_SERVICE_UOM_EDIT_CALL,
  PRODUCTS_SERVICE_UOM_DELETE_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORIES_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL,
  PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL,
  PRODUCTS_SERVICES_CALL_UOMS,
  PRODUCTS_SERVICES_CALL_SUB_CATEGORIES,
  PRODUCTS_SERVICE_RECURRENCES_CALL,
  PRODUCTS_SERVICE_RECURRENCE_ADD_CALL,
  PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL,
  PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL,
  PRODUCTS_SERVICES_CALL_RECURRENCES,
  PRODUCTS_SERVICES_CALL_PERIODS,
  PRODUCTS_SERVICES_CHANGE_STATUSES,
  PRODUCTS_SERVICES_CHANGE_TYPES,
  PRODUCTS_SERVICES_CHANGE_CATEGORIES,
  PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES,
  PRODUCTS_SERVICES_CHANGE_MANUFACTURERS,
  PRODUCTS_SERVICES_CHANGE_UOMS, GET_PRODUCT_VENDOR, getProductVendorPromise,
} from "./sagas-actions";
import {
  onCallProductsServices,
  onSuccessProductsServices,
  onFailedProductsServices,
  switchProductsServicesLoading,
  onSuccessProductsService,
  onFailedProductsService,
  resetProductsService,
  onSuccessProductsServiceAdd,
  onSuccessProductsServiceEdit,
  onSuccessProductsServicesDelete,
  onCallProductsServiceSettings,
  onSuccessProductsServiceSettings,
  onFailedProductsServiceSettings,
  switchProductsServiceSettingLoading,
  onSuccessProductsServiceSettingAdd,
  onSuccessProductsServiceSettingEdit,
  onSuccessProductsServiceSettingDelete,
  onCallProductsServiceAttributes,
  onSuccessProductsServiceAttributes,
  onFailedProductsServiceAttributes,
  onSuccessProductsServiceAttributeAdd,
  switchProductsServicesAttributeChangeLoading,
  onSuccessProductsServicesAttributeChange
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

export const prefix = "/tenantUser/product_service";
const prefixSettings = "/tenantUser/product_service_settings";

// Initial call
function* productsServicesCallGenerator(action) {
  yield put(onCallProductsServices());
  try {
    const {
      payload: { paginationData }
    } = action;

    const params = paramParserForPagination(paginationData);
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/list/table_data?${queryString}`);
    yield put(onSuccessProductsServices(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedProductsServices());
  }
}

// Pagination call
function* productsServicesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* productsServicesWorker() {
    yield put(onCallProductsServices(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/list/table_data?${queryString}`);

      return yield put(onSuccessProductsServices(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      // yield call(resolvePromiseAction, action, value)
      // yield put(onFailedContacts());
    }
  });
}

function* uploadProductsServicePhotoGenerator(file, fileName, productServiceId) {
  const formData = new FormData();
  formData.append("photo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, `${prefix}/photo/${productServiceId}`, formData);
  return data.url;
}

function* productsServiceCallGenerator(action) {
  yield put(resetProductsService());
  try {
    const {
      payload: { productsServiceId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${productsServiceId}`);

    yield put(onSuccessProductsService(data?.product_service));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedProductsService());
  }
}

function* productsServiceAddCallGenerator(action) {
  yield put(switchProductsServicesLoading(true));
  try {
    const {
      payload: { productsServiceData, newPhotoFile, messageFunction }
    } = action;

    // Add product service
    const { data } = yield call(axiosInstance.post, prefix, productsServiceData);
    const returnData = data.product_service[0];

    // Upload photo
    let imageNewUrl;
    if (newPhotoFile?.file) {
      imageNewUrl = yield uploadProductsServicePhotoGenerator(
        newPhotoFile?.file,
        newPhotoFile?.name,
        returnData.product_service_id
      );
    }

    yield put(
      onSuccessProductsServiceAdd({
        ...returnData,
        ...(newPhotoFile?.file && { photo: imageNewUrl })
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchProductsServicesLoading(false));
}

function* productsServiceEditCallGenerator(action) {
  yield put(switchProductsServicesLoading(true));
  try {
    const {
      payload: { productsServiceId, productsServiceData, newPhotoFile, messageFunction }
    } = action;

    // Upload photo
    let imageNewUrl;
    if (newPhotoFile?.file) {
      imageNewUrl = yield uploadProductsServicePhotoGenerator(
        newPhotoFile?.file,
        newPhotoFile?.name,
        productsServiceId
      );
    }

    // Update product service
    const { data } = yield call(axiosInstance.put, `${prefix}/${productsServiceId}`, productsServiceData);

    yield put(
      onSuccessProductsServiceEdit({
        ...data.product_service[0],
        ...(newPhotoFile?.file && { photo: imageNewUrl })
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchProductsServicesLoading(false));
}

function* productsServicesDeleteCallGenerator(action) {
  yield put(switchProductsServicesLoading(true));
  try {
    const {
      payload: { productsServiceIds }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      product_service_ids: productsServiceIds
    });
    yield put(onSuccessProductsServicesDelete(productsServiceIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchProductsServicesLoading(false));
}

function* productsServicesRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      product_service_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

const productsServiceSettingsCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(
      onCallProductsServiceSettings({
        key
      })
    );

    try {
      const { data } = yield call(axiosInstance.get, `${prefixSettings}/${url}`);

      yield put(
        onSuccessProductsServiceSettings({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(
        onFailedProductsServiceSettings({
          key
        })
      );
    }
  };

const productsServiceSettingAddCallGenerator = (key, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: true
      })
    );

    try {
      const {
        payload: { itemData }
      } = action;

      const { data } = yield call(axiosInstance.post, `${prefixSettings}/${key}`, itemData);
      yield put(
        onSuccessProductsServiceSettingAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
      yield put(
        onSuccessProductsServiceAttributeAdd({
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: false
      })
    );
  };

const productsServiceSettingEditCallGenerator = (key, idKey, apiReturnKey) =>
  function* gen(action) {
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId, itemData }
      } = action;
      const { data } = yield call(axiosInstance.put, `${prefixSettings}/${key}/${itemId}`, itemData);

      yield put(
        onSuccessProductsServiceSettingEdit({
          idKey,
          key,
          data: data?.[apiReturnKey]
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: false
      })
    );
  };

const productsServiceSettingDeleteCallGenerator = (key, idKey) =>
  function* gen(action) {
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: true
      })
    );
    try {
      const {
        payload: { itemId }
      } = action;
      yield call(axiosInstance.delete, `${prefixSettings}/${key}/${itemId}`);
      yield put(
        onSuccessProductsServiceSettingDelete({
          idKey,
          key,
          id: itemId
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
    yield put(
      switchProductsServiceSettingLoading({
        key,
        status: false
      })
    );
  };

const productsServiceRecurrenceCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallProductsServiceAttributes({ key }));
    try {
      const { data } = yield call(axiosInstance.get, `${prefixSettings}/list/${url}`);

      yield put(
        onSuccessProductsServiceAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedProductsServiceAttributes({ key }));
    }
  };

const productsServiceAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallProductsServiceAttributes({ key }));

    try {
      const {
        payload: { id }
      } = action;
      const { data } = yield call(
        axiosInstance.get,
        `${prefixSettings}/${key === "sub_category" ? "" : "list/"}${url}${key === "sub_category" ? `/${id}` : ""}`
      );
      yield put(
        onSuccessProductsServiceAttributes({
          key,
          data
        })
      );
    } catch (error) {
      console.log(error, 'this is error');
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedProductsServiceAttributes({ key }));
    }
  };

const productsServicesAttributeChangeCallGenerator = (key, fieldToChange) => function* gen(action) {
  yield put(switchProductsServicesAttributeChangeLoading({
    key,
    status: true
  }));

  try {
    const { payload: { data } } = action;

    if (key === 'group') {
      yield call(axiosInstance.patch, `${prefix}/change_groups`, data);
    } else {
      const { data: resData } = yield call(axiosInstance.patch, `${prefix}/change_setting/${fieldToChange}`, data);
      yield put(onSuccessProductsServicesAttributeChange({
        data: resData?.updatedRows
      }));
    }

  } catch (error) {
    yield put(setError({
      error,
      action
    }));
  }

  yield put(switchProductsServicesAttributeChangeLoading({
    key,
    status: false
  }));
};

function* getProductVendorGenerator(action) {
  yield call(implementPromiseAction, action, function* productVendorWorker() {
    const { payload } = action;
    try {
      const { data } = yield call(axiosInstance.get, `tenantUser/companies/list/select_search?search_value=${payload}`);
      return data;
    } catch (e) {
      console.log(e, 'request failed');
    }
  });

}

export default function* productsServiceSettingsSaga() {
  yield takeEvery(PRODUCTS_SERVICES_CALL, productsServicesCallGenerator);
  yield takeEvery(callProductsServicesPromise, productsServicesCallPromiseGenerator);
  yield takeEvery(PRODUCTS_SERVICES_DELETE_CALL, productsServicesDeleteCallGenerator);
  yield takeEvery(PRODUCTS_SERVICES_RESTORE_CALL, productsServicesRestoreCallGenerator);
  yield takeEvery(PRODUCTS_SERVICE_CALL, productsServiceCallGenerator);
  yield takeEvery(PRODUCTS_SERVICE_ADD_CALL, productsServiceAddCallGenerator);
  yield takeEvery(PRODUCTS_SERVICE_EDIT_CALL, productsServiceEditCallGenerator);

  yield takeEvery(PRODUCTS_SERVICES_CALL_STATUSES, productsServiceAttributesCallGenerator("status", "status"));
  yield takeEvery(PRODUCTS_SERVICES_CALL_TYPES, productsServiceAttributesCallGenerator("type", "type"));
  yield takeEvery(PRODUCTS_SERVICES_CALL_CATEGORIES, productsServiceAttributesCallGenerator("category", "category"));
  yield takeEvery(
    PRODUCTS_SERVICES_CALL_SUB_CATEGORIES,
    productsServiceAttributesCallGenerator("sub_category", "sub_category")
  );
  yield takeEvery(
    PRODUCTS_SERVICES_CALL_MANUFACTURERS,
    productsServiceAttributesCallGenerator("manufacturer", "manufacturer")
  );
  yield takeEvery(PRODUCTS_SERVICES_CALL_UOMS, productsServiceAttributesCallGenerator("uom", "uom"));
  yield takeEvery(
    PRODUCTS_SERVICES_CALL_PERIODS,
    productsServiceRecurrenceCallGenerator("recurrence", "recurrence_option")
  );
  yield takeEvery(
    PRODUCTS_SERVICES_CALL_RECURRENCES,
    productsServiceAttributesCallGenerator("recurrence", "recurrence")
  );

  yield takeEvery(PRODUCTS_SERVICES_CHANGE_STATUSES, productsServicesAttributeChangeCallGenerator('status', 'status_id'));
  yield takeEvery(PRODUCTS_SERVICES_CHANGE_TYPES, productsServicesAttributeChangeCallGenerator('type', 'type_id'));
  yield takeEvery(PRODUCTS_SERVICES_CHANGE_CATEGORIES, productsServicesAttributeChangeCallGenerator('category', 'category_id'));
  yield takeEvery(PRODUCTS_SERVICES_CHANGE_SUB_CATEGORIES, productsServicesAttributeChangeCallGenerator('sub_category', 'sub_category_id'));
  yield takeEvery(PRODUCTS_SERVICES_CHANGE_MANUFACTURERS, productsServicesAttributeChangeCallGenerator('manufacturer', 'manufacturer_id'));
  yield takeEvery(PRODUCTS_SERVICES_CHANGE_UOMS, productsServicesAttributeChangeCallGenerator('uom', 'uom_id'));

  // SETTINGS
  yield takeEvery(PRODUCTS_SERVICE_STATUSES_CALL, productsServiceSettingsCallGenerator("status", "status_list"));
  yield takeEvery(
    PRODUCTS_SERVICE_STATUS_ADD_CALL,
    productsServiceSettingAddCallGenerator("status", "product_service_status")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_STATUS_EDIT_CALL,
    productsServiceSettingEditCallGenerator("status", "product_service_status_id", "product_service_status")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_STATUS_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("status", "product_service_status_id")
  );

  yield takeEvery(PRODUCTS_SERVICE_TYPES_CALL, productsServiceSettingsCallGenerator("type", "type_list"));
  yield takeEvery(
    PRODUCTS_SERVICE_TYPE_ADD_CALL,
    productsServiceSettingAddCallGenerator("type", "product_service_type")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_TYPE_EDIT_CALL,
    productsServiceSettingEditCallGenerator("type", "product_service_type_id", "product_service_type")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_TYPE_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("type", "product_service_type_id")
  );

  yield takeEvery(PRODUCTS_SERVICE_CATEGORIES_CALL, productsServiceSettingsCallGenerator("category", "category_list"));
  yield takeEvery(
    PRODUCTS_SERVICE_CATEGORY_ADD_CALL,
    productsServiceSettingAddCallGenerator("category", "product_service_category")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_CATEGORY_EDIT_CALL,
    productsServiceSettingEditCallGenerator("category", "product_service_category_id", "product_service_category")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_CATEGORY_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("category", "product_service_category_id")
  );

  yield takeEvery(
    PRODUCTS_SERVICE_SUB_CATEGORIES_CALL,
    productsServiceSettingsCallGenerator("sub_category", "sub_category_list")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_SUB_CATEGORY_ADD_CALL,
    productsServiceSettingAddCallGenerator("sub_category", "product_service_sub_category")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_SUB_CATEGORY_EDIT_CALL,
    productsServiceSettingEditCallGenerator(
      "sub_category",
      "product_service_sub_category_id",
      "product_service_sub_category"
    )
  );
  yield takeEvery(
    PRODUCTS_SERVICE_SUB_CATEGORY_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("sub_category", "product_service_sub_category_id")
  );

  yield takeEvery(
    PRODUCTS_SERVICE_MANUFACTURERS_CALL,
    productsServiceSettingsCallGenerator("manufacturer", "manufacturer_list")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_MANUFACTURER_ADD_CALL,
    productsServiceSettingAddCallGenerator("manufacturer", "product_service_manufacturer")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_MANUFACTURER_EDIT_CALL,
    productsServiceSettingEditCallGenerator(
      "manufacturer",
      "product_service_manufacturer_id",
      "product_service_manufacturer"
    )
  );
  yield takeEvery(
    PRODUCTS_SERVICE_MANUFACTURER_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("manufacturer", "product_service_manufacturer_id")
  );

  yield takeEvery(PRODUCTS_SERVICE_UOMS_CALL, productsServiceSettingsCallGenerator("uom", "uom_list"));
  yield takeEvery(PRODUCTS_SERVICE_UOM_ADD_CALL, productsServiceSettingAddCallGenerator("uom", "product_service_uom"));
  yield takeEvery(
    PRODUCTS_SERVICE_UOM_EDIT_CALL,
    productsServiceSettingEditCallGenerator("uom", "product_service_uom_id", "product_service_uom")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_UOM_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("uom", "product_service_uom_id")
  );

  yield takeEvery(
    PRODUCTS_SERVICE_RECURRENCES_CALL,
    productsServiceSettingsCallGenerator("recurrence", "recurrence_list")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_RECURRENCE_ADD_CALL,
    productsServiceSettingAddCallGenerator("recurrence", "product_service_recurrence")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_RECURRENCE_EDIT_CALL,
    productsServiceSettingEditCallGenerator("recurrence", "product_service_recurrence_id", "product_service_recurrence")
  );
  yield takeEvery(
    PRODUCTS_SERVICE_RECURRENCE_DELETE_CALL,
    productsServiceSettingDeleteCallGenerator("recurrence", "product_service_recurrence_id")
  );
  yield takeEvery(
    getProductVendorPromise,
    getProductVendorGenerator
  );
}
