import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

export default makeStyles((theme) => ({
  label: {
    fontSize: "0.8rem!important",
    lineHeight: "1rem!important",
    whiteSpace: "nowrap!important",
    textTransform: "capitalize!important",
    overflow: "hidden!important",
    textOverflow: "ellipsis!important",
    maxWidth: "calc(100% - 30px)!important",
    transform: "translate(14px, 12px) scale(1)!important"
  },
  labelNotCapitalize: {
    textTransform: "none"
  },
  labelFocused: {},
  labelShrink: {
    transform: "translate(15px, -6px) scale(0.75)!important"
  },
  errorText: {
    height: "10px",
    fontSize: "0.5rem",
    margin: theme.spacing(0.2, 0.5, 0, 1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    textAlign: "right",
    color: theme.palette.error.main
    // [theme.breakpoints.up('lg')]: {
    //     height: '18px',
    //     fontSize: '0.75rem'
    // }
  },
  // fieldWrapper: {
  //     width: '100%'
  // },
  // root: {
  //     '.MuiInputBase-root': {
  //         backgroundColor: 'red!important'
  //     }
  // },
  field: {
    marginTop: `${theme.spacing(0.25)}!important`,
    marginBottom: `${theme.spacing(0.25)}!important`,
    // [theme.breakpoints.up('lg')]: {
    //     marginBottom: theme.spacing(0.85)
    // },
    "& .MuiSelect-outlined": {
      display: "flex",
      alignItems: "center"
    },
    "&.MuiInputBase-root > fieldset > legend": {
      fontSize: "0.6rem"
    },
    "&>.MuiInputBase-root.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: theme.spacing(1.1875),
      paddingBottom: theme.spacing(1.1875)
      // [theme.breakpoints.up('lg')]: {
      //     paddingTop: theme.spacing(1.5),
      //     paddingBottom: theme.spacing(1.5)
      // }
    },
    // styles for phone
    "& .MuiPhoneNumber-flagButton": {
      height: 0,
      minWidth: 0,
      display: "none",
      padding: "0!important"
    },
    "& .css-2xhzvc": {
      color: "inherit!important"
    },
    "& .css-2xhzvc.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(211, 47, 47)"
    },
    "& .css-2xhzvc.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(192, 192, 192, 0.24)"
    },
    "& .css-2xhzvc:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255,255,255,0.8)"
    },
    "& .css-2xhzvc.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(192, 192, 192, 0.24)"
    },
    "& .css-2xhzvc.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main
    },
    "& .css-1rcvyyg": {
      color: "rgba(192, 192, 192, 1.24)"
    },
    "& .css-1rcvyyg.Mui-error, .css-1rcvyyg.Mui-disabled": {
      color: "rgba(192, 192, 192, 1.24)"
    },
    "& .css-1rcvyyg.Mui-focused": {
      color: theme.palette.primary.main
    },
    "& .css-igs3ac": {
      borderWidth: "2px",
      borderColor: "rgba(192, 192, 192, 0.24)",
      borderStyle: "groove"
    },
    "& .css-162edyi.Mui-disabled": {
      "-webkit-text-fill-color": "rgba(192, 192, 192, 1.24)"
    },
    "& .css-hjvdj6": {
      height: "10px",
      fontSize: "0.5rem",
      margin: theme.spacing(0.2, 0.5, 0, 1),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      textAlign: "right"
    }
  },
  input: {
    // fontSize: '0.937rem',
    // lineHeight: '1rem',
    // letterSpacing: '0',
    // paddingTop: theme.spacing(1.5),
    // paddingBottom: theme.spacing(1.5),
    "&::placeholder": {
      fontSize: "13px!important"
    },
    "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active": {
      transition: "background-color 500000s",
      "-webkit-text-fill-color": `${theme.palette.text.primary} !important`,
      // '-webkit-box-shadow': `0 0 0 100px ${theme.palette.primary.main} inset`
      "-webkit-box-shadow": "0 0 0 100px transparent inset"
    },
    "&::-webkit-calendar-picker-indicator": {
      ...(theme.palette.mode === "dark" && {
        // TODO -> remove this
        filter: "invert(1)"
      })
    },
    // Textarea height for 4 rows
    "&.MuiInputBase-inputMultiline": {
      height: "80px!important"
    }
  },
  colorPicker: {
    display: "inline-flex",
    alignItems: "center",
    "& > div": {
      minWidth: "40px",
      minHeight: "25px",
      border: `3px solid ${theme.palette.primary.main}`,
      marginRight: theme.spacing(1.5)
    }
  },
  // option: {
  //     padding: 0
  //     // paddingTop: theme.spacing(1.5),
  //     // paddingBottom: theme.spacing(1.5)
  // },
  autoCompleteRoot: {
    "& > div > .MuiAutocomplete-inputRoot": {
      paddingTop: "0!important",
      paddingBottom: "0!important",
      paddingLeft: "0!important"
    }
  },
  largeDropdown: {
    width: "40%",
    minWidth: "250px",
    [theme.breakpoints.up("xl")]: {
      minWidth: "400px"
    }
  },
  mediumDropdown: {
    width: "40%",
    minWidth: "250px"
  },
  checkIcon: {
    fontSize: 80,
    color: green[500],
    fill: green[500],
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1)
  }
}));
