import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { axiosSagaRequest } from "clients/api";
import { setError } from "../error/slice";
import {
  APP_DYNAMIC_CONTENTS_CALL,
  CURRENCY_EXCHANGE_RATES_CALL,
  downloadImageFromUrlCallPromise,
  uploadTempImageCallPromise
} from "./sagas-actions";
import { onCallGeneric, onSuccessGeneric, onFailedGeneric, onSuccessDynamicContents } from "./slice";

function* uploadTempImageCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* uploadTempImageWorker() {
    try {
      const {
        payload: { src }
      } = action;
      const { data } = yield call(
        axiosSagaRequest,
        "get",
        `/base/upload_temp_image?imgUrl=${src}?not-from-cache-please`
      );
      return data?.result;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* downloadImageCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* downloadImageWorker() {
    try {
      const {
        payload: { url }
      } = action;
      const { data } = yield call(
        axiosSagaRequest,
        "get",
        `/base/download_image?imgUrl=${url}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  });
}


function* currencyExchangeRatesCallGenerator(action) {
  yield put(onCallGeneric({ key: "currencyExchangeRates" }));
  try {
    const { data } = yield call(axiosSagaRequest, "get", "/base/currency_percent", {
      params: {
        // base: 'USD',
        // symbols: ['GBP', 'JPY', 'EUR']
      }
    });
    yield put(
      onSuccessGeneric({
        key: "currencyExchangeRates",
        apiReturnKey: "result.rates",
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedGeneric({ key: "currencyExchangeRates" }));
  }
}

function* appDynamicContentsCallGenerator(action) {
  try {
    const { payload: { id } } = action;
    const { data } = yield call(axiosSagaRequest, "get", `base/dynamicContent/${id}`);

    yield put(
      onSuccessDynamicContents({
        id,
        data
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

export default function* generalSaga() {
  yield takeLatest(uploadTempImageCallPromise, uploadTempImageCallPromiseGenerator);
  yield takeLatest(downloadImageFromUrlCallPromise, downloadImageCallPromiseGenerator);
  yield takeLatest(CURRENCY_EXCHANGE_RATES_CALL, currencyExchangeRatesCallGenerator);
  yield takeEvery(APP_DYNAMIC_CONTENTS_CALL, appDynamicContentsCallGenerator);
}
