import { takeEvery, put, call } from "redux-saga/effects";
import axiosInstance from "clients/api";
import { setError } from "../error/slice";
import {
  USERS_CALL,
  USER_CALL,
  USER_ADD_CALL,
  USER_EDIT_CALL,
  USER_SECURITY_ROLES_CALL,
  USER_UI_SETTINGS_EDIT_CALL,
  USER_CHARGE_CALL,
  USER_EDIT_PATCH_CALL
} from "./sagas-actions";
import {
  onCallUsers,
  onSuccessUsers,
  onFailedUsers,
  switchUsersLoading,
  onSuccessUser,
  onFailedUser,
  onSuccessUserAdd,
  onSuccessUserEdit,
  // onSuccessUserDelete
  onSuccessUserCharge,
  onCallUserSecurityRoles,
  onSuccessUserSecurityRoles,
  onFailedUserSecurityRoles,
  onFailedUserCharge
} from "./slice";
import { onSuccessMeUpdate, updateUserLocalProps } from "../user/slice";

function* usersCallGenerator(action) {
  yield put(onCallUsers());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/list");

    yield put(onSuccessUsers(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedUsers());
  }
}

function* userCallGenerator(action) {
  try {
    const {
      payload: { userId }
    } = action;
    const { data } = yield call(axiosInstance.get, "/tenantUser/getById", {
      params: {
        tenantUserId: userId
      }
    });

    yield put(onSuccessUser(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedUser());
  }
}

function* uploadUserPhotoGenerator(file, fileName, userId) {
  const formData = new FormData();
  formData.append("photo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, "/tenantUser/photo", formData, {
    params: {
      tenantUserId: userId
    }
  });

  return data?.url;
}

function* userAddCallGenerator(action) {
  yield put(switchUsersLoading(true));
  try {
    const {
      payload: { userData, newPhotoFile, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.post, "/tenantUser/add", userData);

    let imageNewUrl;
    if (newPhotoFile?.file) {
      imageNewUrl = yield uploadUserPhotoGenerator(
        newPhotoFile?.file,
        newPhotoFile?.name,
        data.userData.tenant_user_id
      );
    }

    yield put(
      onSuccessUserAdd({
        userData: {
          ...data.userData,
          ...(newPhotoFile?.file && { Photo: imageNewUrl })
        }
      })
    );
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchUsersLoading(false));
}

function* userEditCallGenerator(action) {
  yield put(switchUsersLoading(true));
  try {
    const {
      payload: { userData, userId, newPhotoFile, timezone, messageFunction }
    } = action;

    // change photo
    let imageNewUrl;
    if (newPhotoFile?.file) {
      imageNewUrl = yield uploadUserPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, userId);
    }

    // update user data
    const { data } = yield call(axiosInstance.put, `/tenantUser/edit/${userId}`, userData);

    // update user data in list
    yield put(onSuccessUserEdit(data?.updatedTenantUser));

    // if it is current user
    if (timezone) {
      userData.timezone = timezone;
      delete userData.timezone_id;
      yield put(
        updateUserLocalProps({
          ...userData,
          ...(newPhotoFile?.file && { photo: imageNewUrl })
        })
      );
    }
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchUsersLoading(false));
}

function* userEditPatchCallGenerator(action) {
  try {
    const {
      payload: { userData, userId, isAuth, messageFunction }
    } = action;

    const { data } = yield call(axiosInstance.patch, `/tenantUser/edit/${userId}`, userData);

    yield put(onSuccessUserEdit(data?.updatedTenantUser, isAuth));

    if (isAuth) {
      yield put(onSuccessMeUpdate(userData));
    }
    yield data && messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* userEditUiSettingsCallGenerator(action) {
  try {
    const {
      payload: { userId, userUiData }
    } = action;

    // update user ui data
    yield call(axiosInstance.put, `/tenantUser/changeUiSettings/${userId}`, userUiData);
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* userSecurityRolesCallGenerator(action) {
  yield put(onCallUserSecurityRoles());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/securityRoles");

    yield put(onSuccessUserSecurityRoles(data?.securityRoles));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedUserSecurityRoles());
  }
}

function* userChargeCallGenerator(action) {
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/postAdd");
    yield put(onSuccessUserCharge(data));
  } catch (error) {
    yield put(onFailedUserCharge());
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

export default function* usersSaga() {
  yield takeEvery(USERS_CALL, usersCallGenerator);
  yield takeEvery(USER_CALL, userCallGenerator);
  yield takeEvery(USER_ADD_CALL, userAddCallGenerator);
  yield takeEvery(USER_EDIT_CALL, userEditCallGenerator);
  yield takeEvery(USER_EDIT_PATCH_CALL, userEditPatchCallGenerator);
  yield takeEvery(USER_UI_SETTINGS_EDIT_CALL, userEditUiSettingsCallGenerator);
  yield takeEvery(USER_SECURITY_ROLES_CALL, userSecurityRolesCallGenerator);
  yield takeEvery(USER_CHARGE_CALL, userChargeCallGenerator);
}
