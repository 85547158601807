import { createSelector } from "@reduxjs/toolkit";

export const currencyExchangeRatesDataSelector = createSelector(
  (state) => state.generalData.currencyExchangeRates,
  (a) => a
);

export const appDynamicContentsDataSelector = createSelector(
  (state) => state.generalData.dynamicContents,
  (a) => a
);
