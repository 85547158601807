import { createPromiseAction } from "@adobe/redux-saga-promise";

export const UPLOAD_TEMP_IMAGE_CALL_PROMISE = "UPLOAD_TEMP_IMAGE_CALL_PROMISE";
export const DOWNLOAD_IMAGE_FROM_URL_CALL_PROMISE = "DOWNLOAD_IMAGE_FROM_URL_CALL_PROMISE";
export const CURRENCY_EXCHANGE_RATES_CALL = "CURRENCY_EXCHANGE_RATES_CALL";
export const APP_DYNAMIC_CONTENTS_CALL = "APP_DYNAMIC_CONTENTS_CALL";

export const uploadTempImageCallPromise = createPromiseAction(UPLOAD_TEMP_IMAGE_CALL_PROMISE);
export const downloadImageFromUrlCallPromise = createPromiseAction(DOWNLOAD_IMAGE_FROM_URL_CALL_PROMISE);

export function callCurrencyExchangeRates() {
  return { type: CURRENCY_EXCHANGE_RATES_CALL };
}

export function callAppDynamicContents(id) {
  return { type: APP_DYNAMIC_CONTENTS_CALL, payload: { id } };
}