import { createSelector } from "@reduxjs/toolkit";

export const userDataSelector = (store) => {
  return store.userData;
};

export const userSignFactorSelector = (store) => store.userData.factor;

export const userPasswordResetStatusSelector = (store) => store.userData.passwordResetStatus;

export const userModulePermissionSelector = (store, module) => {
  const moduleSum = store.userData?.user[module];

  if (moduleSum !== undefined) {
    return moduleSum;
  }
  return null;
};

export const historySelector = createSelector(
  (state) => state.userData.history,
  (a) => a
);

export const userEULASelector = createSelector(
  (state) => state.userData.userEULA,
  (a) => a
);

export const userEmailDataSelector = createSelector(
  (state) => state.userData.userEmailData,
  (a) => a
);

export const themePropsDataSelector = createSelector(
  (state) => state.userData.themeProps,
  (a) => a
);

export const userStatusSelector = createSelector(
  (state) => state.userData.status_id,
  (a) => a
);
