import Joi from "joi";
import joiDefaultErrorMessages from "../messages";
import { toCurrency } from "utils/helpers";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

export const stringMaxLength = (length = 50) => Joi.string().max(length).messages(joiDefaultErrorMessages);

export const name = Joi.string().max(50).required().messages(joiDefaultErrorMessages);

export const emailSchema = Joi.string()
  .max(100)
  .email({ tlds: { allow: false } })
  // .regex(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  .required()
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid email address"
  });

export const emailSchemaOptional = Joi.string()
  .max(100)
  .email({ tlds: { allow: false } })
  .empty("")
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid email address"
  });

export const emailSchemaMultipleOptional = Joi.array().items({
  code: Joi.string().required().messages(joiDefaultErrorMessages),
  name: Joi.string().required().messages(joiDefaultErrorMessages)
});

export const phoneNumberSchema = Joi.string()
  .regex(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid phone number"
  });

export const faxSchema = Joi.string()
  .regex(/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid fax number"
  });

export const cardSchema = Joi.string()
  .regex(
    /^(3[47][0-9]{13}|(6541|6556)[0-9]{12}|389[0-9]{11}|3(?:0[0-5]|[68][0-9])[0-9]{11}|65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})|63[7-9][0-9]{13}|(?:2131|1800|35\d{3})\d{11}|9[0-9]{15}|(6304|6706|6709|6771)[0-9]{12,15}|(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}|(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))|(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}|(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}|(62[0-9]{14,17})|4[0-9]{12}(?:[0-9]{3})?|(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}))$/
  )
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid card number"
  });

export const cardExpirationDateSchema = Joi.string()
  .regex(/\b(0[1-9]|1[0-2]) \/ ?([0-9]{4}|[0-9]{2})\b/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid date"
  });

export const cardCodeSchema = Joi.string()
  .regex(/^[0-9]{3,4}$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid code"
  });

export const paymentAmountSchema = (required, amount, total, currency) => {
  let schema = Joi.number().min(0).max(total);

  if (required) {
    schema = schema
      .required()
      .min(amount)
      .messages({
        ...joiDefaultErrorMessages,
        "number.min": `Must be greater than ${toCurrency(amount, currency)}`,
        "number.max": "Exceeded total amount"
      });
  } else {
    schema = schema.messages({
      ...joiDefaultErrorMessages,
      "number.min": "Must be greater than 0",
      "number.max": "Exceeded total amount"
    });
  }
  return schema;
};

export const passwordSchema = Joi.string().required().messages(joiDefaultErrorMessages);

export const newPasswordSchema = Joi.string()
  .min(8)
  .max(50)
  .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/\\]).{8,}$/)
  .required()
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Must include a-z, A-Z, 0-9, and symbol"
  });

export const verifyPasswordSchema = Joi.valid(Joi.ref("password")).messages({
  "any.only": "Passwords don’t match."
});

export const subdomainSchema = Joi.string()
  .min(3)
  .max(63)
  // .regex(/^(?!-)[A-Za-z0-9-]{3,63}(?<!-)$/) // not working in safari
  .regex(/^(?!-)[A-Za-z0-9-]{2,62}[a-zA-Z0-9]$/)
  .required()
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid subdomain"
  });

export const domainSchema = Joi.string()
  .min(3)
  .max(63)
  .regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})(?:\.[a-zA-Z]{2,})*$/)
  .required()
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid domain"
  });

export const urlSchema = Joi.string()
  .regex(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid url"
  });

export const socialMediaUrlSchema = Joi.string()
  .regex(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)
  .empty("")
  .messages({
    ...joiDefaultErrorMessages,
    "string.pattern.base": "Invalid url"
  });

export const stateSchema = Joi.when("country", {
  is: "US",
  then: Joi.string().max(50).required().messages(joiDefaultErrorMessages),
  otherwise: Joi.optional()
});

export const zipSchema = Joi.when("country", {
  is: "US",
  then: Joi.string()
    .regex(/^\d{5}(?:[-\s]\d{4})?$/)
    .required()
    .messages({
      ...joiDefaultErrorMessages,
      "string.base": "Is a required field",
      "string.pattern.base": "Invalid zip code"
    }),
  otherwise: Joi.optional()
});

export const provinceSchema = Joi.when("country", {
  is: "CA",
  then: Joi.string().max(50).required().messages(joiDefaultErrorMessages),
  otherwise: Joi.optional()
});

export const postalCodeSchema = Joi.when("country", {
  is: "CA",
  then: Joi.string()
    .regex(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/)
    .required()
    .messages({
      ...joiDefaultErrorMessages,
      "string.pattern.base": "Invalid postal code"
    }),
  otherwise: Joi.optional()
});

// Custom fields validations
const customFieldValidation = Joi.when("type", {
  is: "date",
  then: Joi.string(), // TODO -> validate as DATE
  otherwise: Joi.when("type", {
    is: "number",
    then: Joi.number(),
    otherwise: Joi.when("type", {
      is: "checkbox",
      then: Joi.boolean(),
      otherwise: Joi.when("type", {
        is: "select",
        then: Joi.string(),
        otherwise: Joi.string()
      })
    })
  })
});

export const customFieldsValidations = Joi.array().items({
  custom_field_definition_id: Joi.number().required(),
  required: Joi.boolean().required(),
  type: Joi.string().required(),
  data: Joi.when("required", {
    is: true,
    then: customFieldValidation.required().messages({
      ...joiDefaultErrorMessages,
      "any.invalid": "Invalid value"
    }),
    otherwise: customFieldValidation
      // .empty('')
      .messages({
        ...joiDefaultErrorMessages,
        "any.invalid": "Invalid value"
      })
  })
});

export const dateGreaterThanOrEqual = (compareWithKey = "today") =>
  Joi.custom((value, helpers) => {
    const dateToCompare =
      compareWithKey !== "today" ? helpers.state.ancestors[0][compareWithKey] : dayjs().startOf("day");

    if ((dateToCompare && (!value || isEmpty(value))) || dayjs(value).isBefore(dayjs(dateToCompare))) {
      return helpers.error("date.greater");
    }
    return value;
  }).messages({
    "date.greater": `Must be greater than or equal to ${compareWithKey.replaceAll("_", " ")}`
  });
