import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import MuiPhoneNumber from "mui-phone-number";
import PropTypes from "prop-types";
import React, { memo, useMemo, useState } from "react";
import { Controller } from "react-hook-form-v7";
import ColorPickDialog from "components/complex/dialogs/color-picker";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import useStyles from "../style";

const Field = memo(
  ({
    control,
    type,
    name,
    options,
    defaultValue,
    className,
    // dirty,
    error,
    fullWidth,
    InputLabelProps,
    // hidden,
    customOnFocus,
    isItemColoured,
    ...otherProps
  }) => {
    const classes = useStyles();
    const [isColorPickerDialogOpen, setIsColorPickerDialogOpen] = useState(false);
    const [accordionSelected, setAccordionSelected] = useState([]);

    const defaultProps = useMemo(
      () => ({
        type,
        variant: otherProps.variant,
        label: otherProps?.label || name,
        error: Boolean(error),
        helperText: <span>{error || ""}</span>,
        FormHelperTextProps: {
          className: classes.errorText
        },
        labelPlacement: otherProps?.labelPlacement || "end",
        needsHelper: otherProps?.needsHelper || true,
        margin: "normal",
        size: "small",
        className: classes.field,
        inputProps: {
          className: classes.input,
          autoComplete: "new-password",
          ...(otherProps?.onBlur && { onBlur: otherProps.onBlur })
          // ...(otherProps.onChange && { onChange: otherProps.onChange })
        },
        InputLabelProps: {
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
            shrink: classes.labelShrink
          },
          ...((type === "date" || type === "datetime-local" || type === "phone") && { shrink: true }),
          ...(InputLabelProps && { ...InputLabelProps })
        },
        fullWidth,
        ...otherProps
      }),
      [error, otherProps, InputLabelProps]
    );

    switch (type) {
      case "select-multiple": {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const selectMenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250
            }
          },
          variant: "menu",
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        };
        const { InputLabelProps } = defaultProps;

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => {
              const handleOnChange = (e) => {
                if (
                  e.target.value.find((item) => item.code === "all") &&
                  e.target.value.length === options.length + 1
                ) {
                  field.onChange([]);
                  return;
                }
                if (e.target.value.find((item) => item.code === "all")) {
                  field.onChange(options);
                  return;
                }
                field.onChange(e.target.value);
              };
              return (
                <FormControl className={className} style={{ width: "100%" }}>
                  <InputLabel id={`${name}-label`} {...InputLabelProps}>
                    {defaultProps.label}
                  </InputLabel>
                  <Select
                    error={Boolean(defaultProps.error)}
                    {...field}
                    onChange={handleOnChange}
                    value={
                      Array.isArray(field.value)
                        ? options.filter((option) => field?.value?.some((v) => v.code === option.code))
                        : []
                    }
                    labelId={`${name}-label`}
                    multiple
                    variant="standard"
                    input={
                      <OutlinedInput
                        label={defaultProps.label}
                        size={defaultProps.size}
                        margin="dense"
                        {...defaultProps}
                      />
                    }
                    displayEmpty
                    MenuProps={selectMenuProps}
                    renderValue={(selected) => {
                      if (selected.length === options.length) return "All";
                      return selected.map((v) => v.name).join(", ");
                    }}>
                    {!defaultProps.withoutAll && (
                      <MenuItem className={classes.option} value={{ code: "all", name: "ALL" }} dense>
                        <Checkbox
                          checked={Array.isArray(field.value) ? field?.value?.length === options.length : false}
                          color="primary"
                          size={defaultProps.size}
                        />
                        <ListItemText primary="All" />
                      </MenuItem>
                    )}
                    {options.map((option) => (
                      <MenuItem
                        className={classes.option}
                        key={option.code}
                        value={option}
                        dense
                        disabled={option.disabled}>
                        <Checkbox
                          checked={
                            Array.isArray(field.value) ? !!field.value.find((v) => v.code === option.code) : false
                          }
                          color="primary"
                          size={defaultProps.size}
                          disabled={option.disabled}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.errorText} error={defaultProps.error}>
                    {defaultProps.helperText}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      }
      case "multi-select-enabled": {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const selectMenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250
            }
          },
          variant: "menu",
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        };
        const { InputLabelProps } = defaultProps;
        const filtered = options.filter((item) => !item.disabled);

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => {
              const handleOnChange = (e) => {
                if (
                  e.target.value.find((item) => item.code === "all") &&
                  e.target.value.length === filtered.length + 1
                ) {
                  field.onChange([]);
                  return;
                }
                if (e.target.value.find((item) => item.code === "all")) {
                  field.onChange(filtered);
                  return;
                }
                field.onChange(e.target.value);
              };
              return (
                <FormControl className={className} style={{ width: "100%" }}>
                  <InputLabel id={`${name}-label`} {...InputLabelProps}>
                    {defaultProps.label}
                  </InputLabel>
                  <Select
                    error={Boolean(defaultProps.error)}
                    {...field}
                    onChange={handleOnChange}
                    value={
                      Array.isArray(field.value)
                        ? options.filter((option) => field?.value?.some((v) => v.code === option.code))
                        : []
                    }
                    labelId={`${name}-label`}
                    multiple
                    variant="standard"
                    input={
                      <OutlinedInput
                        label={defaultProps.label}
                        size={defaultProps.size}
                        margin="dense"
                        {...defaultProps}
                      />
                    }
                    displayEmpty
                    MenuProps={selectMenuProps}
                    renderValue={(selected) => {
                      if (filtered.length === 0) return "";
                      if (selected.length === filtered.length) return "All";
                      return selected.map((v) => v.name).join(", ");
                    }}>
                    <MenuItem
                      className={classes.option}
                      value={{ code: "all", name: "ALL" }}
                      dense
                      disabled={!filtered.length}>
                      <Checkbox
                        checked={
                          Array.isArray(field.value) && filtered.length
                            ? field?.value?.length === filtered.length
                            : false
                        }
                        color="primary"
                        size={defaultProps.size}
                      />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem
                        className={classes.option}
                        key={option.code}
                        value={option}
                        dense
                        disabled={option.disabled}>
                        <Checkbox
                          checked={
                            Array.isArray(field.value) ? !!field.value.find((v) => v.code === option.code) : false
                          }
                          color="primary"
                          size={defaultProps.size}
                          disabled={option.disabled}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.errorText} error={defaultProps.error}>
                    {defaultProps.helperText}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      }
      case "multi-select-accordion": {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const selectMenuProps = {
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250
            }
          },
          variant: "menu",
          // getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        };
        const { InputLabelProps } = defaultProps;

        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => {
              const removeDuplicates = (arr) => {
                return arr.reduce((acc, current) => {
                  if (acc.find((item) => item === current)) {
                    return acc;
                  }
                  acc.push(current);
                  return acc;
                }, []);
              };

              const onItemSelectionToggle = (event, id, value) => {
                const parent = options.find((option) => option.id === id);
                if (value) {
                  if (parent) {
                    const newSelected = removeDuplicates([
                      ...accordionSelected,
                      id,
                      ...parent.children.map((item) => item.id)
                    ]);

                    field.onChange(newSelected);
                    return setAccordionSelected(newSelected);
                  }
                  const newSelected = removeDuplicates([...accordionSelected, id]);
                  field.onChange(newSelected);
                  return setAccordionSelected(newSelected);
                }

                if (parent) {
                  const filteredSelected = removeDuplicates(
                    accordionSelected.reduce((acc, current) => {
                      const foundItem = parent.children.find((item) => item.id === current);
                      if (foundItem) {
                        return acc;
                      }
                      if (current === parent.id) {
                        return acc;
                      }
                      acc.push(current);
                      return acc;
                    }, [])
                  );
                  field.onChange(filteredSelected);
                  return setAccordionSelected(filteredSelected);
                }

                const parentId = options.reduce((acc, currnet) => {
                  const foundChild = currnet.children.find((child) => child.id === id);
                  if (foundChild) {
                    return currnet.id;
                  }
                  return acc;
                }, "");
                const filteredSelected = removeDuplicates(
                  accordionSelected.filter((selected) => selected !== id).filter((selected) => selected !== parentId)
                );
                field.onChange(filteredSelected);
                return setAccordionSelected(filteredSelected);
              };

              return (
                <FormControl className={className} style={{ width: "100%" }}>
                  <InputLabel id={`${name}-label`} {...InputLabelProps}>
                    {defaultProps.label}
                  </InputLabel>
                  <Select
                    error={Boolean(defaultProps.error)}
                    {...field}
                    onChange={() => {}}
                    value={Array.isArray(field.value) ? field.value.map((item) => (item.id ? item.id : item)) : []}
                    labelId={`${name}-label`}
                    multiple
                    variant="standard"
                    input={
                      <OutlinedInput
                        label={defaultProps.label}
                        size={defaultProps.size}
                        margin="dense"
                        {...defaultProps}
                      />
                    }
                    displayEmpty
                    MenuProps={selectMenuProps}
                    renderValue={() => {
                      return field.value
                        .map((selected) => {
                          if (selected.name) {
                            return selected.name;
                          }
                          return options.reduce((acc, current) => {
                            if (selected === current.id) {
                              return current.name;
                            }
                            const foundChild = current.children.find((child) => child.id === selected);
                            if (foundChild) {
                              return foundChild.name;
                            }
                            return acc;
                          }, "");
                        })
                        .join(", ");
                    }}>
                    <RichTreeView
                      selectedItems={field.value.map((item) => (item.id ? item.id : item))}
                      onItemSelectionToggle={onItemSelectionToggle}
                      multiSelect
                      checkboxSelection
                      items={options}
                    />
                  </Select>
                  <FormHelperText className={classes.errorText} error={defaultProps.error}>
                    {defaultProps.helperText}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      }
      case "checkbox":
      case "switch": {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { ref, value, ...restFieldProps } }) => {
              const InputComponent = type === "checkbox" ? Checkbox : Switch;
              return (
                <FormControl className={classes.field}>
                  <FormControlLabel
                    control={
                      <InputComponent
                        inputRef={ref}
                        checked={value}
                        {...restFieldProps}
                        {...otherProps}
                        size={defaultProps.size}
                        color="primary"
                        autoComplete={name === "email" || name === "password" ? "off-off" : ""}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        noWrap
                        style={{ opacity: otherProps?.disabled ? 0.5 : 1 }}>
                        {defaultProps.label}
                      </Typography>
                    }
                    labelPlacement={defaultProps.labelPlacement}
                  />
                  {defaultProps.needsHelper && (
                    <FormHelperText className={classes.errorText} error={defaultProps.error}>
                      {defaultProps.helperText}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
        );
      }
      case "radio": {
        return (
          <FormControl component="fieldset" className={classes.field}>
            <Typography component="legend" variant="body1" color="textPrimary">
              {defaultProps.label}
            </Typography>
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ field }) => (
                <RadioGroup row aria-label={name} {...field} {...otherProps}>
                  {options &&
                    options.map((option) => (
                      <FormControlLabel
                        key={option.code || option}
                        control={<Radio color="primary" size={defaultProps.size} />}
                        label={
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                            style={{ opacity: otherProps?.disabled ? 0.5 : 1 }}>
                            {option.name || option}
                          </Typography>
                        }
                        value={option.code || option}
                      />
                    ))}
                </RadioGroup>
              )}
            />
            <FormHelperText className={classes.errorText} error={defaultProps.error}>
              {defaultProps.helperText}
            </FormHelperText>
          </FormControl>
        );
      }
      case "custom-date": {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { ref, value, onChange, ...restFieldProps } }) => (
              <DatePicker
                disablePast={defaultProps.disablePast}
                label={defaultProps.label}
                openTo="day"
                disabled={defaultProps.disabled}
                value={dayjs(value || defaultValue)}
                // InputAdornmentProps={{ position: "start" }}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                slotProps={{
                  textField: {
                    ...defaultProps,
                    size: "small",
                    // error: false,
                    sx: {
                      width: "100%"
                    }
                  }
                }}
              />
            )}
          />
        );
      }
      case "custom-date-time": {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={dayjs(defaultValue)}
            render={({ field: { ref, value, onChange, ...restFieldProps } }) => (
              <DateTimePicker
                disabled={otherProps?.disabled}
                label={defaultProps.label}
                disablePast
                value={dayjs(value || defaultValue || null)}
                onChange={(newValue) => onChange(newValue)}
                slotProps={{
                  textField: {
                    ...defaultProps,
                    sx: {
                      width: "100%"
                    },
                    size: "small"
                  }
                }}
              />
            )}
          />
        );
      }

      // other input type
      default: {
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { ref, ...fieldRestProps } }) => {
              switch (type) {
                case "select": {
                  return (
                    <TextField native inputRef={ref} {...fieldRestProps} {...defaultProps} select>
                      {options &&
                        options.map((option, i) => {
                          if (option.code === "divider") {
                            return <Divider key={option.code + i} />;
                          }

                          if (option.code === "subHeader") {
                            return <ListSubheader>{option.subHeader}</ListSubheader>;
                          }
                          return (
                            <MenuItem
                              key={option.code || option}
                              style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}
                              value={option.code !== undefined ? option.code : option}
                              disabled={option.code === "disabled" || option.disabled}>
                              {option.name || option}
                              {isItemColoured && (
                                <Box
                                  sx={{
                                    backgroundColor: `#${option.color}`,
                                    width: "20px",
                                    height: "15px",
                                    borderRadius: "3px",
                                    marginLeft: "auto"
                                  }}
                                />
                              )}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  );
                }
                case "textarea": {
                  return <TextField inputRef={ref} {...fieldRestProps} multiline rows={4} {...defaultProps} />;
                }
                case "hidden": {
                  return <input ref={ref} {...fieldRestProps} hidden />;
                }
                case "phone": {
                  return <MuiPhoneNumber ref={ref} {...fieldRestProps} {...defaultProps} defaultCountry="us" />;
                }
                case "color-picker": {
                  return (
                    <>
                      <Button className={classes.colorPicker} onClick={() => setIsColorPickerDialogOpen(true)}>
                        <Box sx={{ backgroundColor: fieldRestProps.value }} />

                        {defaultProps.label}
                      </Button>
                      <ColorPickDialog
                        // {...fieldRestProps}
                        color={fieldRestProps.value}
                        closePopup={() => setIsColorPickerDialogOpen(undefined)}
                        isOpen={isColorPickerDialogOpen}
                        handleChange={(color) => fieldRestProps.onChange(color.hex)}
                      />
                    </>
                  );
                }
                default: {
                  return (
                    <TextField
                      {...(customOnFocus && { onFocus: customOnFocus })}
                      inputRef={ref}
                      {...fieldRestProps}
                      {...defaultProps}
                    />
                  );
                }
              }
            }}
          />
        );
      }
    }
  }
);

Field.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        code: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
      }).isRequired
    ])
  ),
  variant: PropTypes.string,
  fullWidth: PropTypes.bool
};

Field.defaultProps = {
  control: undefined,
  defaultValue: undefined,
  error: undefined,
  options: undefined,
  variant: "outlined",
  fullWidth: true
};

export default Field;
