import { implementPromiseAction, rejectPromiseAction, resolvePromiseAction } from "@adobe/redux-saga-promise";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosInstance, { axiosSagaRequest } from "clients/api";
import appModules from "constant/data/app-modules";
import { paramParserForPagination } from "utils/helpers";
import { setError } from "../error/slice";
import qs from "qs";
import {
  COMPANIES_CALL,
  COMPANIES_CALL_ACTIVE_TENANT_USERS,
  COMPANIES_CALL_CLASSIFICATIONS,
  COMPANIES_CALL_CUSTOM_FIELDS,
  COMPANIES_CALL_GROUPS,
  COMPANIES_CALL_SOCIAL_MEDIAS,
  COMPANIES_CALL_STATUSES,
  COMPANIES_CALL_TYPES,
  COMPANIES_CHANGE_CLASSIFICATIONS,
  COMPANIES_CHANGE_ORG_REP,
  COMPANIES_CHANGE_GROUPS,
  COMPANIES_CHANGE_STATUSES,
  COMPANIES_CHANGE_TYPES,
  COMPANIES_DELETE_CALL,
  COMPANIES_RESTORE_CALL,
  COMPANY_ADDRESSES_CALL,
  COMPANY_ADDRESS_ADD_CALL,
  COMPANY_ADDRESS_CALL,
  COMPANY_ADDRESS_DELETE_CALL,
  COMPANY_ADDRESS_EDIT_CALL,
  COMPANY_CALL,
  COMPANY_CONTACTS_CALL,
  COMPANY_EDIT_CALL,
  COMPANY_MAIN_ADDRESS_CALL,
  COMPANY_NOTES_CALL,
  COMPANY_TASKS_CALL,
  callCompaniesPromise,
  callCompanyAddPromise,
  callCompanyEditPromise,
  callSingleCompanyAddPromise,
  COMPANIES_CALL_ORGREPS
} from "./sagas-actions";
import {
  onCallActiveTenantUsers,
  onCallCompanies,
  onCallCompanyAddress,
  onCallCompanyAddresses,
  onCallCompanyAttributes,
  onCallCompanyContacts,
  onCallCompanyCustomFields,
  onCallCompanyMainAddress,
  onCallCompanyNotes,
  onCallCompanySocialMedias,
  onCallCompanyTasks,
  onFailedActiveTenantUsers,
  onFailedCompanies,
  onFailedCompany,
  onFailedCompanyAddress,
  onFailedCompanyAddresses,
  onFailedCompanyAttributes,
  onFailedCompanyContacts,
  onFailedCompanyCustomFields,
  onFailedCompanyMainAddress,
  onFailedCompanyNotes,
  onFailedCompanySocialMedias,
  onFailedCompanyTasks,
  onSuccessActiveTenantUsers,
  onSuccessCompanies,
  onSuccessCompaniesAddressDelete,
  onSuccessCompaniesDelete,
  onSuccessCompany,
  onSuccessCompanyAdd,
  onSuccessCompanyAddress,
  onSuccessCompanyAddressAdd,
  onSuccessCompanyAddressEdit,
  onSuccessCompanyAddresses,
  onSuccessCompanyAttributeChange,
  onSuccessCompanyAttributes,
  onSuccessCompanyContacts,
  onSuccessCompanyCustomFields,
  onSuccessCompanyEdit,
  onSuccessCompanyMainAddress,
  onSuccessCompanyNotes,
  onSuccessCompanySocialMedias,
  onSuccessCompanyTasks,
  switchCompaniesAttributeChangeLoading,
  switchCompanyLoading
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";
import { listViewTypes } from "../../constant/data/list-view-types";

export const prefix = "/tenantUser/companies";
export const addressPrefix = "/tenantUser/companies/company_address";
const appModuleId = appModules.find((t) => t.name === "Company").code;

// Initial call
function* companiesCallGenerator(action) {
  yield put(onCallCompanies());
  try {
    const {
      payload: { paginationData, tenantUserId }
    } = action;
    const params = paramParserForPagination(paginationData);
    if (tenantUserId) {
      params.filter_fields.push({
        name: "tenant_user_id",
        operator: "eq",
        value: tenantUserId
      });
    }
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);
    data.uiViewType = listViewTypes[paginationData.uiViewTypeId];
    data.shouldClear = params.current_page === 1;

    yield put(onSuccessCompanies({...data, paginationData}));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanies());
  }
}

// Pagination call
function* companiesCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* companiesWorker() {
    yield put(onCallCompanies(action.payload));
    try {
      const params = paramParserForPagination(action.payload);
      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/table_data?${queryString}`);

      return yield put(onSuccessCompanies(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });
}

function* companyCallGenerator(action) {
  try {
    const {
      payload: { companyId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${companyId}`);

    yield put(onSuccessCompany(data?.company?.[0]));
    action?.payload?.resolve && action.payload.resolve(data?.company?.[0]);
  } catch (error) {
    action?.payload?.reject && action?.payload?.reject(error);
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompany());
  }
}

function* uploadCompanyPhotoGenerator(file, fileName, companyId) {
  const formData = new FormData();
  formData.append("logo", file, file.name || fileName);
  const { data } = yield call(axiosInstance.put, `${prefix}/logo/${companyId}`, formData);

  return data?.url;
}

function* companyContactsCallGenerator(action) {
  yield put(onCallCompanyContacts());
  try {
    const {
      payload: { companyId }
    } = action;
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/companies/list/contacts/${companyId}`);
    yield put(onSuccessCompanyContacts(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyContacts());
  }
}

function* companyNotesCallGenerator(action) {
  try {
    const {
      payload: { entityId, module, isSocketUpdate }
    } = action;
    yield put(onCallCompanyNotes(isSocketUpdate));
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/note/generic/${module}/?entity_id=${entityId}`);
    yield put(onSuccessCompanyNotes(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyNotes());
  }
}

function* companyTasksCallGenerator(action) {
  const {
    payload: { companyId, isSocketUpdate }
  } = action;
  yield put(onCallCompanyTasks(isSocketUpdate));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `/tenantUser/task/table_data?company_id=${companyId}`);
    yield put(onSuccessCompanyTasks(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyTasks());
  }
}

// Single Company
function* companyAddCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  yield call(implementPromiseAction, action, function* SingleCompanyAddWorker() {
    try {
      const {
        payload: { companyData, newPhotoFile, messageFunction }
      } = action;

      // Company add
      const { data } = yield call(axiosInstance.post, prefix, companyData);
      // Company logo add
      let imageNewUrl;
      if (newPhotoFile?.file) {
        imageNewUrl = yield uploadCompanyPhotoGenerator(
          newPhotoFile?.file,
          newPhotoFile?.name,
          data.createdCompany.company_id
        );
      }
      // Address add
      let addressCreated = {};
      if (companyData.companyAddress) {
        addressCreated = yield call(axiosInstance.post, addressPrefix, {
          company_id: data.createdCompany.company_id,
          ...companyData.companyAddress
        });
      }

      yield put(
        onSuccessCompanyAdd({
          companyData: {
            ...data.createdCompany,
            ...(newPhotoFile?.file && { logo: imageNewUrl }),
            ...addressCreated?.data?.createdCompanyAddress
          }
        })
      );
      yield data && messageFunction && messageFunction();
      yield call(resolvePromiseAction, action, data);
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield call(rejectPromiseAction, action, error);
    }
  });
  yield put(switchCompanyLoading(false));
}

function* companyAddCallPromiseGenerator(action) {
  yield put(switchCompanyLoading(true));

  yield call(implementPromiseAction, action, function* companyAddWorker() {
    try {
      const {
        payload: { companyData, companyAddressData, messageFunction }
      } = action;

      // Company add
      const { data } = yield call(axiosInstance.post, prefix, companyData);

      // Address add
      yield call(axiosInstance.post, addressPrefix, {
        company_id: data.createdCompany.company_id,
        ...companyAddressData
      });
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });

  yield put(switchCompanyLoading(false));
}

function* companyEditPromiseCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  yield call(implementPromiseAction, action, function* companyEditWorker() {
    try {
      const {
        payload: { companyData, companyId, newPhotoFile, companyAddressId, companyAddressData, messageFunction }
      } = action;
      // change logo
      if (newPhotoFile?.file) {
        yield uploadCompanyPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, companyId);
      }

      // update company data
      const { data } = yield call(axiosInstance.put, `${prefix}/${companyId}`, companyData);
      let companyAddress = {};

      if (companyAddressId === undefined) {
        // Add address
        const { data: newData } = yield call(axiosInstance.post, addressPrefix, {
          company_id: data.updatedCompany.company_id,
          ...companyAddressData
        });
        companyAddress = newData?.createdCompanyAddress;
      } else {
        // Edit address
        const { data: newData } = yield call(axiosInstance.put, `${addressPrefix}/${companyAddressId}`, {
          company_id: companyId,
          ...companyAddressData
        });
        companyAddress = newData?.createdCompanyAddress;
      }
      yield put(onSuccessCompanyEdit({ ...data?.updatedCompany, ...companyAddress }));
      yield messageFunction && messageFunction();
      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });
  yield put(switchCompanyLoading(false));
}
function* companyEditCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  try {
    const {
      payload: { companyData, companyId, newPhotoFile, companyAddressId, companyAddressData }
    } = action;
    // change logo
    if (newPhotoFile?.file) {
      yield uploadCompanyPhotoGenerator(newPhotoFile?.file, newPhotoFile?.name, companyId);
    }

    // update company data
    const { data } = yield call(axiosInstance.put, `${prefix}/${companyId}`, companyData);
    let companyAddress = {};

    if (companyAddressId === undefined) {
      // Add address
      const { data: newData } = yield call(axiosInstance.post, addressPrefix, {
        company_id: data.updatedCompany.company_id,
        ...companyAddressData
      });
      companyAddress = newData?.createdCompanyAddress;
    } else {
      // Edit address
      const { data: newData } = yield call(axiosInstance.put, `${addressPrefix}/${companyAddressId}`, {
        company_id: companyId,
        ...companyAddressData
      });
      companyAddress = newData?.createdCompanyAddress;
    }
    yield put(onSuccessCompanyEdit({ ...data?.updatedCompany, ...companyAddress }));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyLoading(false));
}

function* companyDeleteCallGenerator(action) {
  yield put(switchCompanyLoading(true));
  try {
    const {
      payload: { companyIds }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      company_ids: companyIds
    });
    yield put(onSuccessCompaniesDelete(companyIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchCompanyLoading(false));
}

function* companyRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      company_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

// Addresses
function* companyMainAddressCallGenerator(action) {
  const {
    payload: { companyId, withoutLoading }
  } = action;

  yield put(onCallCompanyMainAddress(withoutLoading));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${addressPrefix}/${companyId}/get_by_type_and_company_id`, {
      params: {
        default_type: "default_main"
      }
    });

    yield put(onSuccessCompanyMainAddress(data?.company_address));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyMainAddress());
  }
}

function* companyAddressesCallGenerator(action) {
  const {
    payload: { companyId, withoutLoading }
  } = action;

  yield put(onCallCompanyAddresses(withoutLoading));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${addressPrefix}/${companyId}/get_company_addresses`);

    yield put(onSuccessCompanyAddresses(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyAddresses());
  }
}

function* companyAddressCallGenerator(action) {
  const {
    payload: { companyAddressId, withoutLoading }
  } = action;

  yield put(onCallCompanyAddress(withoutLoading));
  try {
    const { data } = yield call(axiosSagaRequest, "get", `${addressPrefix}/${companyAddressId}`);

    yield put(onSuccessCompanyAddress(data?.company_address));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyAddress());
  }
}

function* companyAddressAddCallGenerator(action) {
  try {
    const {
      payload: { companyAddressData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, addressPrefix, companyAddressData);

    yield put(onSuccessCompanyAddressAdd(data?.createdCompanyAddress));

    if (companyAddressData.default_main) {
      yield companyMainAddressCallGenerator({
        payload: {
          companyId: companyAddressData.company_id,
          withoutLoading: true
        }
      });
    }
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* companyAddressEditCallGenerator(action) {
  try {
    const {
      payload: { companyAddressId, companyAddressData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.put, `${addressPrefix}/${companyAddressId}`, companyAddressData);

    // TODO -> should be updatedCompanyAddress
    yield put(onSuccessCompanyAddressEdit(data?.createdCompanyAddress));

    if (companyAddressData.default_main) {
      yield companyMainAddressCallGenerator({
        payload: {
          companyId: companyAddressData.company_id,
          withoutLoading: true
        }
      });
    }
    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

function* companyAddressDeleteCallGenerator(action) {
  try {
    const {
      payload: { companyAddressIds, messageFunction }
    } = action;
    yield call(axiosInstance.post, `${addressPrefix}/delete_multiple`, {
      company_address_ids: companyAddressIds
    });
    yield put(onSuccessCompaniesAddressDelete(companyAddressIds));

    yield messageFunction && messageFunction();
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
}

const companyAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallCompanyAttributes({ key }));
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/company-settings/list/${url}`);
      yield put(
        onSuccessCompanyAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedCompanyAttributes({ key }));
    }
  };

function* companySocialMediasCallGenerator(action) {
  yield put(onCallCompanySocialMedias());
  try {
    const { data } = yield call(axiosInstance.get, "/tenantUser/social_media");
    yield put(onSuccessCompanySocialMedias(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanySocialMedias());
  }
}

function* companyCustomFieldsCallGenerator(action) {
  yield put(onCallCompanyCustomFields());
  try {
    const { data } = yield call(axiosInstance.get, `/tenantUser/custom-fields/list/${appModuleId}`);

    yield put(onSuccessCompanyCustomFields(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedCompanyCustomFields());
  }
}

function* companyActiveTenantUsersCallGenerator(action) {
  yield put(onCallActiveTenantUsers());
  try {
    const {
      payload: { tenant_user_id }
    } = action;
    const user_id = tenant_user_id ? `?tenant_user_id=${tenant_user_id}` : "";
    const { data } = yield call(axiosInstance.get, `/tenantUser/get_active_tenant_users${user_id}`);
    yield put(onSuccessActiveTenantUsers(data));
  } catch (error) {
    console.log("error", error, "we got error");
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedActiveTenantUsers());
  }
}

const companiesAttributeChangeCallGenerator = (key, fieldToChange) =>
  function* gen(action) {
    yield put(
      switchCompaniesAttributeChangeLoading({
        key,
        status: true
      })
    );

    try {
      const {
        payload: { data }
      } = action;

      if (key === "group") {
        yield call(axiosInstance.patch, "/tenantUser/company/change_groups", data);
      } else {
        const { data: resData } = yield call(
          axiosInstance.patch,
          `/tenantUser/company/change_setting/${fieldToChange}`,
          data
        );
        yield put(
          onSuccessCompanyAttributeChange({
            data: resData?.updatedRows
          })
        );
      }
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }

    yield put(
      switchCompaniesAttributeChangeLoading({
        key,
        status: false
      })
    );
  };

export default function* companiesSaga() {
  yield takeEvery(COMPANIES_CALL, companiesCallGenerator);
  yield takeEvery(callCompaniesPromise, companiesCallPromiseGenerator);
  yield takeLatest(COMPANY_CALL, companyCallGenerator);
  yield takeEvery(callSingleCompanyAddPromise, companyAddCallGenerator);
  yield takeEvery(callCompanyAddPromise, companyAddCallPromiseGenerator);
  yield takeEvery(COMPANY_EDIT_CALL, companyEditCallGenerator);
  yield takeEvery(callCompanyEditPromise, companyEditPromiseCallGenerator);

  yield takeEvery(COMPANIES_DELETE_CALL, companyDeleteCallGenerator);
  yield takeEvery(COMPANIES_RESTORE_CALL, companyRestoreCallGenerator);

  yield takeLatest(COMPANY_CONTACTS_CALL, companyContactsCallGenerator);

  yield takeLatest(COMPANY_NOTES_CALL, companyNotesCallGenerator);

  yield takeLatest(COMPANY_TASKS_CALL, companyTasksCallGenerator);

  yield takeLatest(COMPANY_MAIN_ADDRESS_CALL, companyMainAddressCallGenerator);
  yield takeLatest(COMPANY_ADDRESSES_CALL, companyAddressesCallGenerator);
  yield takeLatest(COMPANY_ADDRESS_CALL, companyAddressCallGenerator);
  yield takeEvery(COMPANY_ADDRESS_ADD_CALL, companyAddressAddCallGenerator);
  yield takeEvery(COMPANY_ADDRESS_EDIT_CALL, companyAddressEditCallGenerator);
  yield takeEvery(COMPANY_ADDRESS_DELETE_CALL, companyAddressDeleteCallGenerator);

  yield takeEvery(COMPANIES_CALL_STATUSES, companyAttributesCallGenerator("status", "company_status"));
  yield takeEvery(COMPANIES_CALL_TYPES, companyAttributesCallGenerator("type", "company_type"));
  yield takeEvery(
    COMPANIES_CALL_CLASSIFICATIONS,
    companyAttributesCallGenerator("industryClassification", "company_industry_classification")
  );
  yield takeEvery(COMPANIES_CALL_ORGREPS, companyAttributesCallGenerator("orgRep", "tenant_user_list"));
  yield takeEvery(COMPANIES_CALL_GROUPS, companyAttributesCallGenerator("group", "company_group"));

  yield takeEvery(COMPANIES_CALL_CUSTOM_FIELDS, companyCustomFieldsCallGenerator);
  yield takeEvery(COMPANIES_CALL_SOCIAL_MEDIAS, companySocialMediasCallGenerator);
  yield takeEvery(COMPANIES_CALL_ACTIVE_TENANT_USERS, companyActiveTenantUsersCallGenerator);

  yield takeEvery(COMPANIES_CHANGE_STATUSES, companiesAttributeChangeCallGenerator("status", "status_id"));
  yield takeEvery(COMPANIES_CHANGE_TYPES, companiesAttributeChangeCallGenerator("type", "type_id"));
  yield takeEvery(
    COMPANIES_CHANGE_CLASSIFICATIONS,
    companiesAttributeChangeCallGenerator("industryClassification", "classification_id")
  );
  yield takeEvery(COMPANIES_CHANGE_ORG_REP, companiesAttributeChangeCallGenerator("orgRep", "tenant_user_id"));
  yield takeEvery(COMPANIES_CHANGE_GROUPS, companiesAttributeChangeCallGenerator("group"));
}
