import { createPromiseAction } from "@adobe/redux-saga-promise";

export const USER_SIGN_IN_CALL = "USER_SIGN_IN_CALL";
export const USER_SSO_CALL = "USER_SSO_CALL";
export const USER_MFA_CALLBACK = "USER_MFA_CALLBACK";
export const USER_GET = "USER_GET";
export const USER_EMAIL_GET = "USER_EMAIL_GET";
export const FORGOT_PASSWORD_CALL = "FORGOT_PASSWORD_CALL";
export const RESET_PASSWORD_CALL = "RESET_PASSWORD_CALL";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_EULA_EDIT = "USER_EULA_EDIT";
export const USER_EULA_GET = "USER_EULA_GET";
export const GENERATE_TAB_ID = "GENERATE_TAB_ID";
export const USER_CHANGE_STATUS_CALL = "USER_CHANGE_STATUS_CALL";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";

export const LOGOUT_EMAIL = "LOGOUT_EMAIL";

export const editUserEULAPromise = createPromiseAction(USER_EULA_EDIT);

export function signIn(signInData) {
  return {
    type: USER_SIGN_IN_CALL,
    payload: { signInData }
  };
}

export function changePassword(data, message) {
  return {
    type: USER_CHANGE_PASSWORD,
    payload: { data: { ...data }, message }
  };
}

export function mfaCallback(authData) {
  return {
    type: USER_MFA_CALLBACK,
    payload: { authData }
  };
}

export function generateTabId() {
  return {
    type: GENERATE_TAB_ID
  };
}

export function getUserByToken(token) {
  return {
    type: USER_GET,
    payload: { token }
  };
}

export function getUserEmail() {
  return { type: USER_EMAIL_GET };
}

export function forgotPassword(email) {
  return {
    type: FORGOT_PASSWORD_CALL,
    payload: { email }
  };
}

export function resetPassword(email, newPassword, resetCode) {
  return {
    type: RESET_PASSWORD_CALL,
    payload: {
      email,
      newPassword,
      resetCode
    }
  };
}

export function refreshToken() {
  return {
    type: USER_REFRESH_TOKEN
  };
}

export function editUserEULA(data) {
  return {
    type: USER_EULA_EDIT,
    payload: { data }
  };
}

export function getUserEULA(token) {
  return {
    type: USER_EULA_GET,
    payload: { token }
  };
}

export function callUserChangeStatus(userId, status) {
  return { type: USER_CHANGE_STATUS_CALL, payload: { userId, status } };
}

export function callLogoutEmail() {
  return { type: LOGOUT_EMAIL };
}

export function callUserSSO(provider) {
  return { type: USER_SSO_CALL, payload: { provider } };
}
