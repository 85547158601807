const durationOptions = [
  {
    code: 15,
    name: "15 Min"
  },
  {
    code: 30,
    name: "30 Min"
  },
  {
    code: 45,
    name: "45 Min"
  },
  {
    code: 60,
    name: "60 Min"
  },
  {
    code: "custom",
    name: "Custom"
  }
];

export default durationOptions;
