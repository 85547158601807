import { takeEvery, put, call } from "redux-saga/effects";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import axiosInstance from "clients/api";
import { paramParserForPagination } from "utils/helpers";
import { setError } from "../error/slice";
import qs from "qs";
import {
  callTaskAddPromise,
  callTasksPromise,
  TASKS_CALL,
  TASKS_EDIT_CALL,
  TASK_ADD_CALL,
  TASK_CALL,
  TASK_CALL_PRIORITIES,
  TASK_CALL_TAGS,
  TASK_CALL_TYPES,
  TASK_DELETE_CALL,
  TASK_RESTORE_CALL,
  TASK_EDIT_CALL,
  TASK_CALL_STATUSES
} from "./sagas-actions";
import {
  onCallTasks,
  onSuccessTasks,
  onFailedTasks,
  onSuccessTaskAttributes,
  onCallTaskAttributes,
  onFailedTaskAttributes,
  switchTaskLoading,
  onSuccessTaskAdd,
  onSuccessTask,
  onFailedTask,
  onSuccessTaskEdit,
  onSuccessTasksDelete,
  onSuccessTasksEdit
  // onSuccessTaskDelete
} from "./slice";
import { onSuccessRestoreItems, switchDeletedItemsLoading } from "../deleted-items/slice";

export const prefix = "/tenantUser/task";

// Initial call
function* tasksCallGenerator(action) {
  yield put(onCallTasks());
  try {
    const {
      payload: { paginationData, tenantUserId }
    } = action;
    const params = paramParserForPagination(paginationData);
    if (tenantUserId) {
      params.filter_fields.push({
        name: "tenant_user_id",
        operator: "eq",
        value: tenantUserId
      });
    }
    const queryString = qs.stringify(params);
    const { data } = yield call(axiosInstance.get, `${prefix}/list/table_data?${queryString}`);
    yield put(onSuccessTasks(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTasks());
  }
}

function* taskCallGenerator(action) {
  try {
    const {
      payload: { taskId }
    } = action;
    const { data } = yield call(axiosInstance.get, `${prefix}/${taskId}`);
    yield put(onSuccessTask(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
    yield put(onFailedTask());
  }
}

// Single Company
function* taskAddCallGenerator(action) {
  yield put(switchTaskLoading(true));
  try {
    const {
      payload: { taskData, messageFunction }
    } = action;
    const { data } = yield call(axiosInstance.post, prefix, taskData);
    yield messageFunction && messageFunction();
    if (data.external_event_id) {
      yield messageFunction && messageFunction("Meeting saved");
    }
    yield put(
      onSuccessTaskAdd({
        taskData: {
          ...data
        }
      })
    );
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTaskLoading(false));
}

function* taskAddCallPromiseGenerator(action) {
  yield put(switchTaskLoading(true));

  yield call(implementPromiseAction, action, function* taskAddWorker() {
    try {
      const { payload } = action;
      const { data } = yield call(axiosInstance.post, prefix, payload);

      yield put(
        onSuccessTaskAdd({
          taskData: {
            ...data
          }
        })
      );

      return data;
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      return Promise.reject(error);
    }
  });

  yield put(switchTaskLoading(false));
}

// Pagination call
function* tasksCallPromiseGenerator(action) {
  yield call(implementPromiseAction, action, function* tasksWorker() {
    yield put(onCallTasks(action.payload));
    try {
      const params = paramParserForPagination(action.payload);

      const queryString = qs.stringify(params);
      const { data } = yield call(axiosInstance.get, `${prefix}/list/table_data?${queryString}`);

      return yield put(onSuccessTasks(data));
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
    }
  });
}

function* taskEditCallGenerator(action) {
  yield put(switchTaskLoading(true));
  try {
    const {
      payload: { taskData, taskId, messageFunction }
    } = action;
    // update task data
    const { data } = yield call(axiosInstance.put, `${prefix}/${taskId}`, taskData);
    yield put(onSuccessTaskEdit(data));
    yield messageFunction && messageFunction();
    if (data.external_event_id) {
      yield messageFunction && messageFunction("Meeting saved");
    }
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTaskLoading(false));
}

function* tasksEditCallGenerator(action) {
  yield put(switchTaskLoading(true));
  try {
    const {
      payload: { taskData }
    } = action;
    // update tasks data
    const { data } = yield call(axiosInstance.put, `${prefix}`, taskData);
    yield put(onSuccessTasksEdit(data));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTaskLoading(false));
}

function* taskDeleteCallGenerator(action) {
  yield put(switchTaskLoading(true));
  try {
    const {
      payload: { taskIds, isSingle }
    } = action;
    yield call(axiosInstance.post, `${prefix}/delete_multiple`, {
      task_ids: taskIds,
      single: isSingle
    });
    yield put(onSuccessTasksDelete(taskIds));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchTaskLoading(false));
}

function* taskRestoreCallGenerator(action) {
  yield put(switchDeletedItemsLoading(true));
  try {
    const {
      payload: { ids }
    } = action;
    yield call(axiosInstance.post, `${prefix}/undo_deleted`, {
      task_ids: ids
    });
    yield put(onSuccessRestoreItems(ids));
  } catch (error) {
    yield put(
      setError({
        error,
        action
      })
    );
  }
  yield put(switchDeletedItemsLoading(false));
}

const taskAttributesCallGenerator = (key, url) =>
  function* gen(action) {
    yield put(onCallTaskAttributes({ key }));
    try {
      const { data } = yield call(axiosInstance.get, `/tenantUser/task-settings/${url}`);
      yield put(
        onSuccessTaskAttributes({
          key,
          data
        })
      );
    } catch (error) {
      yield put(
        setError({
          error,
          action
        })
      );
      yield put(onFailedTaskAttributes({ key }));
    }
  };

export default function* tasksSaga() {
  yield takeEvery(TASKS_CALL, tasksCallGenerator);
  yield takeEvery(TASK_CALL, taskCallGenerator);
  yield takeEvery(TASK_ADD_CALL, taskAddCallGenerator);
  yield takeEvery(callTaskAddPromise, taskAddCallPromiseGenerator);
  yield takeEvery(TASK_EDIT_CALL, taskEditCallGenerator);
  yield takeEvery(TASKS_EDIT_CALL, tasksEditCallGenerator);
  yield takeEvery(TASK_DELETE_CALL, taskDeleteCallGenerator);
  yield takeEvery(TASK_RESTORE_CALL, taskRestoreCallGenerator);
  yield takeEvery(callTasksPromise, tasksCallPromiseGenerator);
  yield takeEvery(TASK_CALL_PRIORITIES, taskAttributesCallGenerator("priority", "task_priority_list"));
  yield takeEvery(TASK_CALL_TYPES, taskAttributesCallGenerator("type", "task_type_list"));
  yield takeEvery(TASK_CALL_TAGS, taskAttributesCallGenerator("tag", "task_tag_definition_list"));
  yield takeEvery(TASK_CALL_STATUSES, taskAttributesCallGenerator("status", "task_status_list"));
}
