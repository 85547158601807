import { createSlice } from "@reduxjs/toolkit";

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    permissions: {
      isFetched: false,
      isFetching: false,
      data: [],
      error: null
    }
  },
  reducers: {
    onCallGetPermissions: (state) => {
      state.permissions.isFetched = false;
      state.permissions.isFetching = true;
    },
    onCallGetPermissionsSuccess: (state, { payload }) => {
      state.permissions.isFetched = true;
      state.permissions.isFetching = false;
      state.permissions.data = payload;
      state.permissions.error = null;
    },
    onCallGetPermissionsFailure: (state, { payload }) => {
      state.permissions.isFetched = true;
      state.permissions.isFetching = false;
      state.permissions.data = [];
      state.permissions.error = payload;
    }
  }
});

export const {
  onCallGetPermissions,
  onCallGetPermissionsSuccess,
  onCallGetPermissionsFailure
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
